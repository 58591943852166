import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Button,
} from '@mui/material';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { HandoverItemType, Participant, ProtocolNotifications } from 'src/types/handover';
import { PROTOCOL_NAMESPACE } from 'src/i18n';
import useAsync from 'src/hooks/useAsync';

interface IProps {
  participants: Participant[];
  publicLink: string;
  handoverId: string;
  currentTab?: string;
  handleRefresh: () => void;
  sendWebBrokerNotification: (
    participantIds: string[],
    handoverId: string,
    itemType?: HandoverItemType,
    destinationType?: { [participantId: string]: 'sms' | 'email' },
  ) => Promise<unknown>;
  addNotifications: (notifications: ProtocolNotifications[]) => void;
  switchTab: 'sellers' | 'buyers' | 'participants';
}

const ParticipantsTableMobile = ({
  participants,
  publicLink,
  handoverId,
  currentTab,
  handleRefresh,
  sendWebBrokerNotification,
  addNotifications,
  switchTab,
}: IProps) => {
  const { t } = useTranslation(PROTOCOL_NAMESPACE);
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>(
    [],
  );
  const [selectParticipantsText, setSelectParticipantsText] = useState<string>("");
  const [destinationType, setDestinationType] = useState<{
    [participantId: string]: 'email' | 'sms';
  }>({});

  useEffect(() => {
    setSelectedParticipants([]);

    setDestinationType(
      participants.reduce(
        (acc, p) => ({
          ...acc,
          [p.id]: 'sms',
        }),
        {},
      ),
    );

    switch (switchTab) {
      case 'sellers':
        setSelectParticipantsText(t('Velg selger(e)'));
        break;
      case 'buyers':
        setSelectParticipantsText(t('Velg kjoper(e)'));
        break;
      case 'participants':
        setSelectParticipantsText(t('Velg deltager(e)'));
        break;
      default:
        setSelectParticipantsText(t('Velg deltager(e)'));
    }

  }, [currentTab, participants, switchTab]);

  const { execute: executeSendNotification, isLoading: isLoadingSend } = useAsync({
    fn: sendWebBrokerNotification,
    options: {
      successCb: (params) => {
        addNotifications(params.sms || []);
        addNotifications(params.email || []);
        setSelectedParticipants([]);
      },
      toast: {
        useToast: true,
        successMessage: `Notification${
          selectedParticipants.length > 1 ? 's' : ''
        } has been sent`,
      },
    },
  });

  const sendNotification = useCallback(async () => {
    const destinations: {
      [participantId: string]: 'email' | 'sms';
    } = {};

    for (const id of Object.keys(destinationType)) {
      if (selectedParticipants.includes(id)) {
        destinations[id] = destinationType[id];
      }
    }

    await executeSendNotification(
      selectedParticipants,
      handoverId,
      currentTab,
      destinations,
    );
    await handleRefresh();
  }, [selectedParticipants]);

  const onSelectAllClick = () => {
    if (selectedParticipants.length) {
      setSelectedParticipants([]);
    } else {
      setSelectedParticipants(participants.map((p) => p.id));
    }
  };

  const isSelected = (id: string) => {
    return selectedParticipants.indexOf(id) > -1;
  };

  const onItemSelect = (id: string) => {
    if (isSelected(id)) {
      setSelectedParticipants((prevState) => prevState.filter((p) => p !== id));
    } else {
      setSelectedParticipants((prevState) => [...prevState, id]);
    }
  };

  const onSelectDestinationType = (
    participantId: string,
    type: 'sms' | 'email',
  ) => {
    setDestinationType((prevState) => ({
      ...prevState,
      [participantId]: type,
    }));
  };

  return (
    <div
      style={{
        paddingLeft: '18px',
        paddingRight: '24px',
        borderRight: '1px solid #e0e0e0',
      }}
    >
      <Typography style={{ margin: '12px 0' }} variant="h6">
        {selectParticipantsText}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checkedIcon={
                    <CheckBoxOutlinedIcon style={{ color: '#588504' }} />
                  }
                  checked={participants.length === selectedParticipants.length}
                  onChange={onSelectAllClick}
                />
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {participants.map((item) => {
              const fullName = `${item.firstName} ${item.lastName}`;
              return (
                <TableRow key={item.id}>
                  <TableCell
                    style={{ borderBottom: 'none' }}
                    padding="checkbox"
                  >
                    <Checkbox
                      color="primary"
                      checkedIcon={
                        <CheckBoxOutlinedIcon style={{ color: '#588504' }} />
                      }
                      checked={isSelected(item.id)}
                      onChange={() => onItemSelect(item.id)}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                      }}
                    >
                      <ChatBubbleOutlineOutlinedIcon
                        onClick={() => onSelectDestinationType(item.id, 'sms')}
                        style={{
                          color:
                            destinationType[item.id] === 'sms'
                              ? '#588504'
                              : 'rgb(38, 50, 56)',
                        }}
                      />
                      {/*<EmailOutlinedIcon*/}
                      {/*  onClick={() =>*/}
                      {/*    onSelectDestinationType(item.id, 'email')*/}
                      {/*  }*/}
                      {/*  style={{*/}
                      {/*    color:*/}
                      {/*      destinationType[item.id] === 'email'*/}
                      {/*        ? '#588504'*/}
                      {/*        : 'rgb(38, 50, 56)',*/}
                      {/*  }}*/}
                      {/*/>*/}
                    </div>
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>
                    <div>
                      <div style={{ fontWeight: '600' }}>{fullName}</div>
                      <div>{item.phone}</div>
                      <div>{item.email}</div>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '24px',
          marginBottom: '24px',
        }}
      >
        <Button
          variant="outlined"
          href={publicLink}
          target="_blank"
          style={{
            marginRight: '18px',
            borderRadius: '45px',
            // borderRadius: '18px',
            borderColor: '#000000',
            color: '#000000',
            textTransform: 'none',
          }}
        >
          {t('common.openLink')}
        </Button>
        <Button
          disabled={selectedParticipants.length === 0}
          color="primary"
          variant="contained"
          size="large"
          style={{
            borderRadius: '45px',
            textTransform: 'none',
          }}
          sx={{
            backgroundColor: '#000000',
            color: '#C5FA64',
            '& .Mui-disabled': {
              color: '#787878',
              backgroundColor: '#D1D1D1',
            },
            '&:hover': {
              backgroundColor: '#000000',
            },
          }}
          onClick={sendNotification}
        >
          {t('buttons.send')}
        </Button>
      </div>
    </div>
  );
};

export default ParticipantsTableMobile;
