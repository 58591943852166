import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Button, Chip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { statusToColor, translateStatus } from 'src/pages/sendout/commonUtils';
import {
  HandoverItemType,
  NotificationStatus,
  ProtocolNotifications,
  ProtocolStatus,
} from 'src/types/handover';
import { PROTOCOL_NAMESPACE } from 'src/i18n';

dayjs.extend(utc);

interface IProps {
  notifications: ProtocolNotifications[];
  itemData: {
    itemType: HandoverItemType;
    handoverId: string;
    publicLinks: string[];
  };
  cancelNotification: (id: string) => void;
}

const NotificationsTable: React.FC<IProps> = ({
  notifications,
  itemData,
  cancelNotification,
}) => {
  const { t } = useTranslation(PROTOCOL_NAMESPACE);
  const cancel = (n: ProtocolNotifications) => {
    cancelNotification(n.id);
  };
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Type utsendelse</TableCell>
            { !(itemData.itemType === HandoverItemType.DYNAMIC) &&
              <TableCell>Rolle</TableCell>
            }
            <TableCell>Sendt til</TableCell>
            <TableCell>Sendt Via</TableCell>
            <TableCell>Tidspunkt</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications.map((n) => {
            const fullName = `${n.participant?.firstName} ${n.participant?.lastName}`;
            const title = itemData.itemType === HandoverItemType.PROTOCOL && n.protocolStatus === ProtocolStatus.PRE_FLOW
            ? t(`common.markedsplass`)
            : !(itemData.itemType === HandoverItemType.DYNAMIC)
              ? t(`common.${itemData.itemType}`)
              : "";
            return (
              <TableRow key={n.id}>
                <TableCell>
                  {title} {n.type}
                </TableCell>
                { !(itemData.itemType === HandoverItemType.DYNAMIC) &&
                  <TableCell>{t(`common.${n.participant?.role}`)}</TableCell>
                }
                <TableCell>{fullName}</TableCell>
                <TableCell>
                  {n.type === 'sms'
                    ? n.participant?.phone
                    : n.participant?.email}
                </TableCell>
                <TableCell>
                  {dayjs(n.scheduledAt).format('DD-MM-YYYY HH:mm')}
                </TableCell>
                <TableCell>
                  <Chip
                    label={translateStatus(n.status)}
                    style={{
                      backgroundColor: statusToColor(n.status),
                    }}
                  />
                  {n.status == NotificationStatus.SCHEDULED && (
                    <Button
                      variant="outlined"
                      onClick={() => cancel(n)}
                      style={{
                        marginLeft: '18px',
                        borderRadius: '45px',
                        borderColor: '#000000',
                        color: '#000000',
                        textTransform: 'none',
                      }}
                    >
                      Kansellere
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NotificationsTable;
