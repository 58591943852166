import React from 'react';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  switchWrapper: {
    display: 'inline-flex',
    position: 'relative',
    borderRadius: '25px',
    backgroundColor: '#FFFFFF',
    padding: '5px',
    width: '200px',
    height: '40px',
    justifyContent: 'space-between',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  switchOption: {
    flex: 1,
    textAlign: 'center',
    lineHeight: '30px',
    fontWeight: 'bold',
    zIndex: 1,
    cursor: 'pointer',
    transition: 'color 0.3s ease',
  },
  active: {
    color: '#000',
  },
  inactive: {
    color: '#aaa',
  },
  switchIndicator: {
    position: 'absolute',
    top: '5px',
    bottom: '5px',
    width: '50%',
    borderRadius: '25px',
    backgroundColor: '#F4FEE1',
    transition: 'left 0.3s ease',
  },
}));

interface IProps {
  value: 'buyers' | 'sellers';
  onChange: (value: 'buyers' | 'sellers') => void;
}

const RoleSwitch = ({ value, onChange }: IProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.switchWrapper}>
      <div
        className={clsx(
          classes.switchOption,
          value === 'sellers' ? classes.active : classes.inactive,
        )}
        onClick={() => onChange('sellers')}
      >
        Til Selger(e)
      </div>
      <div
        className={clsx(
          classes.switchOption,
          value === 'buyers' ? classes.active : classes.inactive,
        )}
        onClick={() => onChange('buyers')}
      >
        Til Kjøper(e)
      </div>
      <div
        className={classes.switchIndicator}
        style={{
          left: value === 'sellers' ? '5px' : 'calc(100% - 50% - 5px)',
        }}
      />
    </div>
  );
};

export default RoleSwitch;
