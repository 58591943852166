import ApiService from 'src/services/api/ApiService';
import {
  HandoverItemType,
  ProtocolStatus,
  SettlementStatus,
} from 'src/types/handover';
import { NotificationCounts, NotificationType } from 'src/types/types';
import { CreateOrSendLeadOtherOptions } from 'src/types/types';

const api = ApiService.getInstance();

export const list = async ({
  page,
  countPerPage,
  filters = {},
  tableFilters = {},
  orderDirection,
  orderBy,
  reportDates,
}: {
  page: number;
  countPerPage: number;
  tableFilters?: { [key: string]: string };
  reportDates?: any;
  filters?: { [key: string]: string };
  orderDirection?: 'asc' | 'desc';
  orderBy?: { field: string };
}) => {
  const params = {
    page: page + 1,
    countPerPage,
    orderBy,
    orderDirection,
    tableFilters,
    reportDates,
    ...filters,
  };
  return await api.get(`/handovers`, { params });
};

export const listLeadsFlow = async ({
  page,
  countPerPage,
  filters = {},
  tableFilters = {},
  orderDirection,
  orderBy,
  reportDates,
}: {
  page: number;
  countPerPage: number;
  tableFilters?: { [key: string]: string };
  reportDates?: any;
  filters?: { [key: string]: string };
  orderDirection?: 'asc' | 'desc';
  orderBy?: { field: string };
}) => {
  const params = {
    page: page + 1,
    countPerPage,
    orderBy,
    orderDirection,
    tableFilters,
    reportDates,
    ...filters,
  };
  return await api.get(`/handovers/list-leads-flow`, { params });
};

export const item = async (id: string, type?: string) => {
  return await api.get(`/handovers/${id}${type ? '/' + type : ''}`);
};

export const getHandoverSchedule = async ({
  id,
  type,
  showCancelled = false,
  hash,
}: {
  id: string;
  type?: string;
  showCancelled?: boolean;
  hash?: string;
}) => {
  const url = `/handovers/${id}/schedule/${type ?? ''}?showCancelled=${showCancelled}${hash ? `&hash=${hash}` : ''}`;
  return await api.get(url);
};

export const getHandoverActivities = async ({
  id,
  type,
}: {
  id: string;
  type?: string;
}) => {
  return await api.get(`/handovers/${id}/activities${type ? '/' + type : ''}`);
};

export const removeHandoverAnswer = async (
  handoverId: string,
  answerId: string,
  type = 'protocol',
) => {
  return await api.delete(
    `/handovers/${handoverId}/remove-answer/${answerId}/${type}`,
  );
};

export const getHandoverLogs = async (id: string) => {
  return await api.get(`/handovers/${id}/logs`);
};

export const create = async (data: any) => {
  return await api.post('/handovers', data);
};

export const updateByAdmin = async (id: string, data: any, type?: string) => {
  return await api.patch(`/handovers/${id}`, { params: data, type });
};

export const updateStatus = async (
  id: string,
  status: ProtocolStatus | SettlementStatus,
  type = 'protocol',
) => {
  return await api.patch(`/handovers/${id}/status`, { status, itemType: type });
};

export const resetSign = async (
  id: string,
  type = 'protocol',
  role?: string,
) => {
  return await api.post(
    `/handovers/${id}/reset-sign/${type}${role ? `/${role}` : ''}`,
  );
};

export const fullReset = async (
  id: string,
  type = 'protocol',
  role?: string,
) => {
  return await api.post(
    `/handovers/${id}/full-reset/${type}${role ? `/${role}` : ''}`,
  );
};

export const updateFetchWithoutAutoSMSValue = async (
  id: string,
  value: boolean,
) => {
  return await api.patch(`/handovers/${id}/new-construction`, { value });
};

export const updateProtocolConfigTypeValue = async (
  id: string,
  value: string,
) => {
  return await api.patch(`/handovers/${id}/protocol-config-type`, { value });
};

export const updateInAutoSendOut = async (id: string, value: boolean) => {
  return await api.patch(`/handovers/${id}/in-auto-send-out`, { value });
};

export const sendDocuments = async (handoverId: string, data: any) => {
  return await api.post(`/handovers/${handoverId}/send-documents`, data);
};

export const sendElectricityLead = async ({
  id,
  participantId,
  monitorsValues = [],
  meteringPointId,
  createOrSendLeadOtherOptions,
}: {
  id: string;
  participantId: string;
  monitorsValues?: any[];
  meteringPointId?: string;
  createOrSendLeadOtherOptions?: CreateOrSendLeadOtherOptions;
}): Promise<any> => {
  return await api.post(`/handovers/${id}/electricity`, {
    participantId,
    monitorsValues,
    meteringPointId,
    createOrSendLeadOtherOptions,
  });
};

export const sendProviderLead = async (
  id: string,
  docType: string,
  dynamicId?: string,
) => {
  return await api.post(`/handovers/${id}/document`, {
    docType,
    dynamicId,
  });
};

export const updateParticipant = async (
  handoverId: string,
  participantId: string,
  data: any,
) => {
  return await api.patch(
    `/handovers/${handoverId}/participant/${participantId}`,
    data,
  );
};

export const syncParticipants = async (handoverId: string) => {
  return await api.post(`/handovers/${handoverId}/sync-participants`);
};

export const createParticipant = async (handoverId: string, data: any) => {
  return await api.post(`/handovers/${handoverId}/participant`, data);
};

export const updateDate = async (handoverId: string, date: string) => {
  return await api.patch(`/handovers/${handoverId}/handover-date`, {
    date,
  });
};

export const updateCustomReadingDate = async (
  handoverId: string,
  customReadingDate: string,
) => {
  return await api.patch(`/handovers/${handoverId}/custom-reading-date`, {
    customReadingDate,
  });
};

export const updateBidAcceptanceDate = async (
  handoverId: string,
  date: string,
) => {
  return await api.patch(`/handovers/${handoverId}/bid-acceptance-date`, {
    date,
  });
};

export const updateAddress = async (
  handoverId: string,
  data: { address: string; zipCode: string; city: string },
) => {
  return await api.patch(`/handovers/${handoverId}/address`, {
    ...data,
  });
};

export const updatePropertyInfo = async (
  handoverId: string,
  data: {
    region: string;
    sq: string;
    energyLabel: string;
    energyColor: string;
    propertyType: string;
  },
) => {
  return await api.patch(`/handovers/${handoverId}/property-info`, {
    ...data,
  });
};

export const getCases = async (params?: { [key: string]: string }) => {
  return await api.get(`/handovers/provider-cases`, { params });
};

export const createLeadsFlowItem = async (params?: {
  [key: string]: string;
}) => {
  return await api.post(`/handovers/leads-flow-item`, { ...params });
};

export const getXml = async (caseId: string, handoverProviderId: string) => {
  const params = { caseId, handoverProviderId };
  return await api.get(`/handovers/get-xml`, {
    params,
    headers: { 'Content-Type': 'application/xml' },
    responseType: 'blob',
  });
};

export const sendOnlyElectricityScrive = async (
  handoverId: string,
  participantId: string,
  providerId: string,
  monitorsValues: any,
) => {
  const data = {
    providerId,
    participantId,
    monitorsValues,
  };

  return await api.post(`/handovers/${handoverId}/electricity-generator`, data);
};

export const syncScriveData = async (
  handoverId: string,
  scriveDocumentId: string,
  type?: string,
) => {
  return await api.get(
    `/handovers/${handoverId}/sync-scrive-data/${scriveDocumentId}${
      type ? `/${type}` : ''
    }`,
  );
};

export const createFromCase = async (
  caseId: string,
  handoverProviderId: string,
  handoverDate?: string,
  caseData?: {
    [K in any]: never;
  },
  type?: string,
  isTest = false,
  bidAcceptanceDate?: string,
  generateDummy = false,
) => {
  let url;
  switch (type) {
    case 'immediate-sync':
    case 'sync':
      url = `/handovers/provider-case-sync`;
      break;
    case 'case':
    default:
      url = `/handovers/provider-case`;
      break;
  }
  return await api.post(url, {
    caseId,
    handoverProviderId,
    handoverDate,
    caseData,
    isTest,
    bidAcceptanceDate,
    generateDummy,
    ...(type === 'immediate-sync' && { immediateProcess: true }),
  });
};

export const getNotificationsCount = async (): Promise<NotificationCounts> => {
  return await api.get(`/notifications/count`);
};

export const updateNotification = async (
  notificationId: string,
  date: string,
  status?: string,
) => {
  return await api.patch(`/notifications/${notificationId}`, {
    date,
    status,
  });
};

export const getSyncInfo = async (
  {
    page,
    countPerPage,
    filters,
    tableFilters = {},
    orderDirection,
    orderBy,
  }: any, //TableRequest FIXTYPE
) => {
  const params = {
    page: page !== undefined ? page + 1 : page,
    countPerPage,
    orderBy,
    orderDirection,
    tableFilters,
    ...filters,
  };
  return await api.get(`/handovers/sync-info`, { params });
};

export const getNotifications = async (
  {
    page,
    countPerPage,
    filters,
    tableFilters = {},
    orderDirection,
    orderBy,
  }: any, //TableRequest FIXTYPE
) => {
  const params = {
    page: page !== undefined ? page + 1 : page,
    countPerPage,
    orderBy,
    orderDirection,
    tableFilters,
    ...filters,
  };
  return await api.get(`/notifications`, { params });
};

export const cancelNotification = async (notificationId: string) => {
  return await api.get(`/notifications/${notificationId}/cancel`);
};

export const cancelTodayNotifications = async () => {
  return await api.get(`/notifications/cancel-all`);
};

export const cancelOldNotifications = async () => {
  return await api.get(`/notifications/cancel-old`);
};

export const cancelCurrentNotifications = async (
  configType: string,
  isCancellingOld: boolean,
  notificationType: NotificationType,
) => {
  return await api.patch(`/notifications/cancel/${configType}`, {
    isCancellingOld,
    notificationType,
  });
};

export const getHandoverByPublicLink = async (hash: string) => {
  return await api.post(`/handovers/getByPublicLink/${hash}`);
};

export const getHandoverByDynamicLink = async (hash: string) => {
  return await api.post(`/handovers/getByDynamicPublicLink/${hash}`);
};

export const resendDynamicFormDoc = async (
  handoverId: string,
  formId: string,
) => {
  return await api.post(
    `/handovers/${handoverId}/resend-dynamic-form-document/${formId}`,
  );
};

export const getHandoverBySettlementPublicLink = async (hash: string) => {
  return await api.post(`/handovers/getBySettlementPublicLink/${hash}`);
};

export const createSettlement = async (handoverId: string) => {
  return await api.post('/settlement', {
    handoverId,
  });
};

export const removeParticipant = async (
  handoverId: string,
  participantId: string,
  type: HandoverItemType,
) => {
  return await api.delete(
    `/handovers/${handoverId}/participants/${participantId}/${
      type === HandoverItemType.SETTLEMENT
        ? 'sp'
        : type === HandoverItemType.LEADS_FLOW
        ? 'lf'
        : type.includes('dynamic')
        ? type
        : 'hp'
    }`,
  );
};

export const cancelVismaBrokerNotification = async (
  notificationId: string,
  itemType: HandoverItemType,
  token: string,
) => {
  return await api.delete(
    `/notifications/${notificationId}/${itemType}/web-broker-notify/visma?token=${token}`,
  );
};

export const cancelVitecBrokerNotification = async (
  notificationId: string,
  itemType: HandoverItemType,
  token: string,
) => {
  return await api.delete(
    `/notifications/${notificationId}/${itemType}/web-broker-notify/vitec?token=${token}`,
  );
};

export const generateDiagramForCaseHandling = async (handoverId: string) => {
  return await api.get(`/handovers/generate-diagram/${handoverId}`);
};

export const uploadHousingPlan = async (handoverId: string, imgFile: File) => {
  return await api.post(
    `/handovers/${handoverId}/upload-housing-plan`,
    { imgFile },
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
};
