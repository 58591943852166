import { Dayjs } from 'dayjs';
import {
  Agency,
  HandoverProvider,
  NotificationChannel,
} from 'src/types/agency';
import { InsuranceTypes } from 'src/types/serviceProvider';
import { Languages } from 'src/types/locale';

export enum ParticipantRole {
  BUYER = 'buyer',
  SELLER = 'seller',
  BROKER = 'broker',
  CLIENT = 'client',
  COMMON = 'common',
  PROSPECT = 'prospect',
  BOARDCHAIR = 'boardchair',
}

export enum ParticipantType {
  PERSON = 'person',
  COMPANY = 'company',
}

export enum ParticipantSource {
  CLIENT = 'client',
  SYNC = 'sync',
  ADMIN = 'admin',
  REST_API = 'rest_api',
}

export enum ProtocolAnswerStatus {
  READY = 'ready',
  WARNING = 'warning',
  DONE = 'DONE',
}

export enum HandoverStatus {
  DRAFT = 'draft',
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'inProgress',
  IS_SIGNING = 'isSigning',
  DONE = 'done',
}

export enum ProtocolStatus {
  READY = 'ready',
  PRE_FLOW = 'pre-flow',
  HANDOVER = 'handover',
  WAITING_DONE = 'waiting-done',
  DONE = 'done',
}

export interface MonitorValue {
  meterValue: string;
  meterNumber: string;
  meterImages?: string[];
  qrScanned?: boolean;
}

export enum SettlementStatus {
  READY = 'ready',
  SETTLEMENT = 'settlement',
  DONE = 'done',
}

export enum LeadsFlowStatus {
  READY = 'ready',
  STARTED = 'started',
  DONE = 'done',
}

export enum DynamicFormStatus {
  READY = 'ready',
  DONE = 'done',
}

export interface DynamicForm {
  id: string;
  config: any;
  title: string;
}

export enum SyncItemStatus {
  QUEUED = 'queued',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
  CANCELED = 'canceled',
  SKIPPED = 'skipped',
}

export enum LeadStatus {
  CONFIRMED = 'Confirmed',
  REGRET = 'Regret',
  EXISTING_CUSTOMER = 'Existing Customer',
  ERROR = 'Error',
  RECONCILIATION_SENT = 'Reconciliation sent',
  WAITING_FOR_RESPONSE = 'Waiting for response',
}

export enum LeadDeliveryStatus {
  CREATED = 'Created',
  DELIVERED = 'Delivered',
  API_ERROR = 'API Error',
}

export enum APIErrorType {
  MalerpunktIdAPIError = 'malerpunktIdAPIError',
  CreateLeadAPIError = 'createLeadAPIError',
  Other = 'other',
}

export enum HandoverItemType {
  PROTOCOL = 'protocol',
  SETTLEMENT = 'settlement',
  LEADS_FLOW = 'leads-flow',
  DYNAMIC = 'dynamic',
}
export interface MonitorValue {
  meterValue: string;
  meterNumber: string;
  meterImages?: string[];
}

export interface BankAccount {
  owner: string;
  number: string;
  numberConfirm?: string;
  bankName?: string;
  netShare?: number;
}

export interface Loan {
  owner?: string;
  amount?: string;
  borrower?: string;
  debt?: string;
  lender?: string;
  number?: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  accountNumber?: string;
  access?: string;
  accessPersonName?: string;
  otherName?: string;
}

export interface Equity {
  equityAmount: string;
  equityBank: string;
  heritage?: boolean;
  heritageDate?: Dayjs;
  other?: boolean;
  otherComment?: string;
  saleOwnHome?: boolean;
  saleDate?: string;
  saleShares?: boolean;
  employerDividendOrBonus?: boolean;
  savedFunds?: boolean;
  accountNumber?: string;
}

export interface DoorKey {
  door: string;
  code: string;
  pcs: number;
}

export interface LeadAuthor {
  authorEmail?: string;
  authorPhone?: string;
  authorName?: string;
  authorRole?: string;
  address?: string;
  fullInfo?: string;
}

export interface Participant {
  id: string;
  firstName: string;
  lastName: string;
  initialFirstName?: string;
  initialLastName?: string;
  companyName?: string;
  organisationNumber?: string;
  externalId: string;
  email: string;
  phone: string;
  birthDate: string | Date | Dayjs | null; //MaterialUiPickersDate | null;
  isPartner?: boolean;
  nationality: string[];
  address: string;
  postalCode: string;
  postArea: string;
  isActive: boolean;
  useForLead: boolean;
  isFilled: boolean;
  notifications?: ProtocolNotifications[];
  ssn?: string;
  type: ParticipantType;
  proxyFullName?: string;
  proxyAddress?: string;
  proxyPhone?: string;
  isProxy?: boolean;
  proxySsn?: string;
  proxyMail?: string;
  isRemoved: boolean;
  isAttorney?: boolean;
  createdAt?: any;
  source?: string;
  subRole?: string;
  role: any;
  isPowerOfAttorneyFor?: string;
  isPresent?: boolean; // only if more than one participant
  agencyData: Record<string, any>;
}

export type ParticipantWithRole = Omit<Participant, 'role'> & {
  role: {
    key: string;
  };
};

export interface SettlementParticipant {
  id: string;
  firstName: string;
  lastName: string;
  initialFirstName?: string;
  initialLastName?: string;
  companyName?: string;
  organisationNumber?: string;
  email: string;
  phone: string;
  notificationChannel?: NotificationChannel;
  birthDate: string | Date | Dayjs | null; //MaterialUiPickersDate | null;
  isPartner?: boolean;
  nationality: string[];
  isActive?: boolean;
  useForLead?: boolean;
  isFilled?: boolean;
  isAttorney?: boolean;
  attorneyAddress?: string;
  attorneyFullName?: string;
  address?: string;
  isSigningHimself?: boolean;
  signOnBehalfData?: {
    person?: string;
    personAddress?: string;
    confirmationImages?: string[];
    poaImages?: string[];
    comments?: string[];
  };
  notifications?: ProtocolNotifications[];
  ssn?: string;
  type: ParticipantType;
  isRemoved?: boolean;
  postalCode?: string;
  postArea?: string;
  createdAt?: any;
  source?: string;
  role?: any;
  occupation?: string;
  employer?: string;
  industry?: string;
  industryRisk?: string;
  leadingRole?: boolean;
  subRole?: string;
  isPowerOfAttorneyFor?: string;
  closeRelationshipsAbroad?: any;
  closeRelationshipsList?: string[];
}

export type SettlementParticipantFormData = Omit<
  SettlementParticipant,
  'id' | 'role'
> & {
  id?: string;
  role?: string;
};

export enum BuyerType {
  COMPANY = 'Company',
  PERSON = 'Person',
}

export interface LogItem {
  id: string;
  type: string;
  createdAt: string;
  url: string;
  method: string;
  request: string;
  response: string;
  status: number;
  isSuccess: boolean;
  renderedCellValue?: any;
}

export interface Activity {
  id: string;
  createdAt: string;
  value: any;
  status: string;
  question: string;
  questionTitle: string;
  role: string;
  roleKey: string;
}

export interface Protocol {
  id: string;
  status: ProtocolStatus;
  isCompleteParticipants?: boolean;
  handover: Handover;
  leads: Lead[];
  participants: any[];
  notifications: ProtocolNotifications[];
  image: string;
  isAllParticipantsShouldSign: boolean;
  protocolLinks?: PublicLink[];
  scriveBuyerDocumentId?: string;
  scriveSellerDocumentId?: string;
  protocolAnswers?: {
    [K in any]: never;
  }[];
  commonLanguage?: Languages;
  buyerLanguage?: Languages;
  sellerLanguage?: Languages;
  canShowSubRole?: boolean;
  isSignAvailable?: boolean;
}

export interface LeadsFlow {
  id: string;
  status: LeadsFlowStatus;
  handover: Handover;
  participants: any[];
  notifications: any[];
  image: string;
  publicLinks?: PublicLink[];
}

export interface Settlement {
  id: string;
  status: ProtocolStatus;
  handover: Handover;
  participants?: any[];
  settlementParticipants?: SettlementParticipant[];
  image: string;
  scriveBuyerDocumentId?: string;
  scriveSellerDocumentId?: string;
  scriveBuyerData?: {
    [K in any]: never;
  };
  scriveSellerData?: {
    [K in any]: never;
  };
  settlementLinks?: PublicLink[];
  settlementAnswers?: any[];
  leads?: Lead[];
}

export interface Dynamic {
  id: string;
  title: string;
  status: string;
  hash: string;
  language: string;
  providerOrderId: string;
  dynamicForm: {
    id: string;
    title: string;
    description: string;
  };
  answers: {
    status: string;
    answerData: object;
    step: {
      questionName: string;
    };
  }[];
  participants: any[];
  scriveDocumentId?: string;
  handover?: Handover;
}

export interface ProtocolConfig {
  id: string;
  config: any;
  agencies: Agency[] | null;
  name?: string;
}

export interface SettlementConfig {
  id: string;
  config: any;
  agencies: Agency[] | null;
  isActive: boolean;
  isEditable: boolean;
  name?: string;
}

export enum ConfigTypes {
  PRE_FLOW = 'pre-flow',
  HANDOVER = 'handover',
  SETTLEMENT = 'settlement',
  LEADS_FLOW = 'leads-flow',
}

export enum ConfigType {
  PROTOCOL = 'protocol-config',
  SETTLEMENT = 'settlement-config',
  LEADS_FLOW = 'leads-flow-config',
  DYNAMIC = 'dynamic',
}

export type QuestionNamesForRole<T> = {
  [key in ParticipantRole]: T[];
};

export type RolesWithQuestionNamesForType<T> = {
  [key in ConfigTypes]: QuestionNamesForRole<T>;
};

export type Configs<T> = {
  config: T;
  defaultConfig: T;
  createdAt: string;
  id: string;
  isActive: boolean;
  isEditable: boolean;
  name: string | null;
};

export enum ProtocolQuestionName {
  PARTICIPANTS = 'participants',
  CLEANING = 'cleaning',
  ELECTRICITY = 'electricity',
  KEYS = 'keys',
  FIRE_SAFETY = 'fire_safety',
  WATER = 'water',
  GAS = 'gas',
  EXPANSES = 'expanses',
  SETTLEMENT = 'settlement',
  REMARKS = 'remarks',
  SIGNATURE = 'signature',
  STORAGE = 'storage',
  INTERNET = 'internet',
  HELD_BACK = 'held_back',
  PURCHASE_PRICE = 'purchase_price',
  INSURANCE = 'insurance',
  MOVING = 'moving',
  BANK = 'bank',
  OTHER = 'other',
  COLD_WATER = 'cold_water',
  HOT_WATER = 'hot_water',
  CENTRAL_HEAT = 'central_heat',
  WARMTH = 'warmth',
  PROVIDER = 'provider',
  CONTRACT_CONDITION = 'contract_condition',
  INVENTORY = 'inventory',
  PROPERTY = 'property',
  KEYS_REMAX_AT = 'keys_remax_at',
  INSURANCE_REMAX_AT = 'insurance_remax_at',
  BUYER_SIGNATURE = 'buyer_signature',
  SELLER_SIGNATURE = 'seller_signature',
  HOME_REVIEW = 'home_review',
  PLOT_REVIEW = 'plot_review',
  DELIVERABLES = 'deliverables',
  BUYER_SIGNING_METHOD = 'buyer_signing_method',
  SELLER_SIGNING_METHOD = 'seller_signing_method',
  SECURITY = 'security',
  CHECKLIST = 'checklist',
  CASE_HANDLING = 'case_handling',
  SETTLEMENT_SUMMARY = 'settlement_summary',
  FURNITURE_AND_INVENTORY = 'furniture_and_inventory',
  GENERAL = 'general',
  ROOM_CHECKLIST = 'room_checklist',
  DEPOSIT = 'deposit',
  HOME_INSURANCE = 'home_insurance',
  APPEAL_DEADLINE = 'appeal_deadline',
  ALARM = 'alarm',
  COMPLETION_CERTIFICATE = 'completion_certificate',
}

export const signatureQuestions = [
  ProtocolQuestionName.SIGNATURE,
  ProtocolQuestionName.SELLER_SIGNATURE,
  ProtocolQuestionName.BUYER_SIGNATURE,
];

export enum SettlementQuestionName {
  BUYER_PARTICIPANTS = 'buyer_participants',
  SELLER_PARTICIPANTS = 'seller_participants',
  SIGNATURE = 'signature',
  BUYER_SIGNATURE = 'buyer_signature',
  SELLER_SIGNATURE = 'seller_signature',
  BUYER_ADDRESS = 'buyer_address',
  SELLER_ADDRESS = 'seller_address',
  BUYER_BANK_ACCOUNT = 'buyer_bank_account',
  SELLER_BANK_ACCOUNTS = 'seller_bank_accounts',
  EQUITY = 'equity',
  BUYER_TYPE = 'buyer_type',
  PURPOSE_BUYING = 'purpose_buying',
  PEP = 'pep',
  LOANS = 'loans',
  FINANCE = 'finance',
  MORTGAGE = 'mortgage',
  INIT = 'init',
  HOME = 'home',
  DONE = 'done',
  BUYER_SIGNING_METHOD = 'buyer_signing_method',
  SELLER_SIGNING_METHOD = 'seller_signing_method',
  ELECTRICITY_CALCULATOR = 'electricity_calculator',
  BUYER_INSURANCE = 'buyer_insurance',
  HOME_INSURANCE = 'home_insurance',
  SELLER_INSURANCE = 'seller_insurance',
  INTERNET = 'internet',
  GENERAL = 'general',
  BUYER_SHARE_OF_OWNERSHIP = 'buyer_share_of_ownership',
  COMMON_EXPENSES = 'common_expenses',
}

export enum LeadsFlowQuestionName {
  PARTICIPANTS = 'participants',
  BANK = 'bank',
  ELECTRICITY = 'electricity',
  CLEANING = 'cleaning',
  INTERNET = 'internet',
  STORAGE = 'storage',
  SECURITY = 'security',
  INSURANCE = 'insurance',
  MOVING = 'moving',
  OTHER = 'other',
}

export const serviceProviderQuestions = [
  ProtocolQuestionName.CLEANING,
  ProtocolQuestionName.STORAGE,
  ProtocolQuestionName.INSURANCE,
  ProtocolQuestionName.MOVING,
  ProtocolQuestionName.BANK,
  ProtocolQuestionName.OTHER,
  ProtocolQuestionName.INTERNET,
  ProtocolQuestionName.ELECTRICITY,
];

export interface Locale {
  id: string;
  key: string;
  language: string;
  text: string;
}

export interface LeadsFlowConfig {
  id: string;
  config: any;
  name: string;
  isActive: boolean;
  isEditable: boolean;
  createdAt: string;
  locales?: Locale[];
}

export enum SourceItemStatusForQuestion {
  PRE_FLOW = 'pre-flow',
  HANDOVER = 'handover',
  SETTLEMENT = 'settlement',
  LEADS_FLOW = 'leads-flow',
}

export interface PublicQuestion {
  id: string;
  questionName: ProtocolQuestionName;
  sourceItemStatus: SourceItemStatusForQuestion;
  title: string;
  description: string;
  position: number;
  isActive: boolean;
}

export interface ProtocolQuestion {
  id: string;
  protocolStatus: ProtocolStatus;
  questionName: ProtocolQuestionName;
  isActive: boolean;
  description: string;
  title: string;
  disabled?: boolean;
}

export interface SettlementQuestion {
  id: string;
  settlementStatus: SettlementStatus;
  questionName: SettlementQuestionName;
  isActive: boolean;
  description: string;
  title: string;
  disabled?: boolean;
}

export enum DynamicFormQuestionName {
  CUSTOM = 'custom',
  PEP = 'pep',
  SELLER_SELF_DECLARATION = 'seller_self_declaration',
  SIGNING_METHOD = 'signing_method',
  SIGNATURE = 'signature',
  GENERAL = 'general',
  SELLER_LOANS = 'seller_loans',
  BUYER_LOANS = 'buyer_loans',
  SELLER_ADDRESS = 'seller_address',
  SELLER_BANK_ACCOUNTS = 'seller_bank_accounts',
  SELLER_INSURANCE = 'seller_insurance',
  SELLER_SIGNATURE = 'seller_signature',
  RENOVATION = 'renovation',
  ASSIGNMENT_REASON = 'assignment_reason',
  PARTICIPANT = 'participant',
  HOUSING_ASSOCIATION = 'housing_association',
  COMMON_EXPENSES = 'common_expenses',
  BUYER_BANK_ACCOUNT = 'buyer_bank_account',
}

export interface DynamicFormQuestion {
  id: string;
  dynamicFormStatus: DynamicFormStatus;
  questionName: DynamicFormQuestionName;
  isActive: boolean;
  description: string;
  title: string;
  disabled?: boolean;
}

interface CommonObject {
  [key: string]: any;
}
interface VitecFirstRefusal {
  preemptive?: any;
  preEmptiveDeadline?: any;
}
export interface Handover {
  id: string;
  additionalInfo?: string;
  protocol?: Protocol;
  settlement?: Settlement;
  leadsFlow?: LeadsFlow;
  dynamic?: Dynamic[];
  customReadingDate: string;
  agency: Agency;
  address: string;
  cleaned: boolean;
  city: string;
  zipCode: string;
  status: HandoverStatus;
  billed: boolean;
  discount: number;
  scriveDocumentId?: string;
  createdAt: string;
  lastSyncDate: string;
  logItems?: LogItem[];
  electricityProviderData: any;
  assignmentNumber: string;
  date: string;
  agencyData?: {
    [K in any]: any;
  };
  scriveData?: {
    [K in any]: never;
  };
  errors?: string;
  timezone: string;
  electricityScriveLinkId?: string;
  electricityPdfLink?: string;
  electricityScriveStatus?: string;
  scriveStatus?: string;
  buyerScriveStatus?: string;
  sellerScriveStatus?: string;
  providerHandoverId?: string;
  isNotFilled?: boolean;
  newProperty?: boolean;
  isRental?: boolean;
  isReturnRental?: boolean;
  isPlot?: boolean;
  isValuation?: boolean;
  project?: string;
  unit?: string;
  housingPlanImages?: string[];
  fetchWithoutAutoSMS?: boolean;
  inAutoSendOut?: boolean;
  mainUseAutoSendOut?: boolean;
  energyLabel?: string;
  energyColor?: string;
  sq?: string;
  region?: string;
  leadDeliveredAt?: string;
  additionalData?: {
    [K in any]: never;
  };
  salesCost?: number;
  equity?: number;
  resetSigningReasons?: string[];
  bidAcceptedDate?: string | undefined;
  electricityScriveDataArray?: any[];
  municipalityRegion?: { county: string };
  sellerSignLink?: string;
  buyerSignLink?: string;
  caseData?: {
    firstRefusalData?: VitecFirstRefusal | CommonObject[];
    attorneyDocument?: CommonObject[];
    typeOwnership?: string;
    attorney?: boolean;
  };
  leadsFlowData?: {
    // The frozen-flag is used when sendImmediately is set to true. Frozen-flag is set to true when consent is accepted during the leadsflow-process.
    // When the frozen-flag is set to true, it will cause the handover to be set to DONE when updateStep is triggered.
    // This will cause the handover to be set to DONE and all scheduled notifications to be set to canceled.
    frozen?: boolean;
  };
  deleted?: boolean;
  smsCleanupInterval?: string;
  noSeller: boolean;
  duplicatedFromHandover?: Handover;
}

export interface ReportsHandoversList {
  total: number;
  data: Handover[];
}

export type ProtocolParticipantDataValues = {
  updatedAt?: any;
  value: any;
  status: string;
};

export type ProtocolParticipantData = {
  [key in ParticipantRole]: ProtocolParticipantDataValues;
};

export interface ProtocolData {
  questionName: ProtocolQuestionName;
  hasSettlementElectricityAnswer?: string;
  title: string;
  status: string;
  description: string;
  position: number;
  options: any;
  data: ProtocolParticipantData;
  id: string;
  isComment: boolean;
  isActive: boolean;
  withhold: boolean;
  config?: any;
  isReversed?: boolean;
  icon?: string;
}

export type SettlementData = Omit<
  ProtocolData,
  'questionName' | 'isComment'
> & {
  questionName: SettlementQuestionName;
};

export type DynamicFormsData = Omit<ProtocolData, 'questionName'> & {
  questionName: DynamicFormQuestionName;
  icon?: string;
};

export type HandoverDynamicForm = {
  scriveDocumentId: string;
};

export interface ProtocolContextData {
  handover: Handover;
  participants: Participant[];
  allParticipants?: Participant[] | SettlementParticipant[];
  protocolData: ProtocolData[];
  settlementData: SettlementData[];
  dynamicFormsData: DynamicFormsData[];
  handoverDynamicFormData: HandoverDynamicForm;
  leadsFlow: {
    id: string;
    sendImmediately: boolean;
    status: LeadsFlowStatus;
    leads: Lead[];
    config?: any;
    language?: Languages;
  };
  protocol: Protocol;
  role: ParticipantRole;
  locale: string;
  isSignAvailable: boolean;
  signed: boolean;
  changeProtocolData: any;
  isSigned: string;
  isBuyerCompany?: boolean;
  marketplaceContent?: any;
  multiLanguageSupport?: boolean;
  useMarketplace?: boolean;
  showHeader?: boolean;
}

export type ProtocolContextType = ProtocolContextData;

export interface ProtocolLink {
  id: string;
  hash: string;
  role: ParticipantRole;
}

export interface PublicLink {
  id: string;
  hash: string;
  role: ParticipantRole;
}

export enum NotificationStatus {
  SCHEDULED = 'scheduled',
  DELIVERED = 'delivered',
  EMAIL = 'email',
  CANCELED = 'canceled',
  FAILED = 'failed',
}

export interface ProtocolNotifications {
  id: string;
  status: NotificationStatus;
  type?: string;
  createdAt?: string;
  updatedAt?: string;
  scheduledAt: string;
  protocolStatus: ProtocolStatus;
  settlementStatus?: SettlementStatus;
  handoverDynamicFormStatus?: DynamicFormStatus;
  participantRole?: {
    key: ParticipantRole;
  };
  settlementParticipant?: {
    firstName?: string;
  };
  participant?: Participant;
  protocol?: Protocol;
  settlement?: Settlement;
  leadsFlow?: LeadsFlow;
  handoverDynamicForm?: Dynamic;
  content?: CommonObject;
  manualCreated?: boolean;
  author?: string;
  overrideNotificationChannel: NotificationChannel;
  renderedCellValue?: any;
}

export interface SettlementNotifications {
  id: string;
  status: NotificationStatus;
  type?: string;
  createdAt?: string;
  updatedAt?: string;
  scheduledAt: string;
  settlementStatus: SettlementStatus;
  participantRole?: {
    key: ParticipantRole;
  };
  participant?: SettlementParticipant;
  settlement?: Settlement;
  content?: CommonObject;
  manualCreated?: boolean;
  author?: string;
}

export interface LeadsFlowtNotifications {
  id: string;
  status: NotificationStatus;
  type?: string;
  createdAt?: string;
  updatedAt?: string;
  scheduledAt: string;
  leadsFlowStatus: LeadsFlowStatus;
  participantRole?: {
    key: ParticipantRole;
  };
  participant?: Participant;
  settlement?: Settlement;
  leadsFlow?: LeadsFlow;
  content?: CommonObject;
  manualCreated?: boolean;
  author?: string;
}

export interface ServiceProvider {
  id: string;
  contractId?: string;
  logo?: string;
  marketplaceBackground?: string;
  title?: string;
  usps?: string;
  intro?: string;
  type?: string;
  country?: {
    id: string;
    name: string;
  };
  agreementTypes: string[];
  app: boolean;
  collectiveInvoicing: boolean;
  electricCar: boolean;
  benefitsProgram: boolean;
  price?: number;
  fixedPrice?: number;
  ranking?: number;
  fixedMonthlyPrice?: number;
  variablePrice?: number;
  variableMonthlyPrice?: number;
  currency?: string;
  unit?: string;
  isActive?: boolean;
  enabledForCompanies?: boolean;
  isTop?: boolean;
  fullInfo?: string;
  createdAt?: string;
  scriveDocumentId?: string;
  emailTemplate?: string;
  useEmail?: boolean;
  useApi?: boolean;
  useScrive?: boolean;
  useLogoForMarketplace?: boolean;
  //isSignImmediately?: boolean;
  serviceProviderApi?: {
    id: string;
    key: string;
    name: string;
  };
  serviceValidatorApi?: {
    id: string;
    key: string;
    name: string;
  };
  email?: string;
  agencies?: Agency[];
  localAgencies?: Agency[];
  consentText?: string;
  supportEmail?: string;
  fixed: string;
  surcharge: string;
  guaranteedDuration: string;
  monthsBindingDuration: string;
  additionalProduct: string;
  moreInfoLink?: string;
  followMethod: string;
  paymentPlace: string;
  invoicePeriod: string;
  notificationMethod: string;
  paperInvoiceCost: string;
  energyClassification?: string;
  typeOfLoan?: string;
  borrowingRate?: number;
  repaymentPeriod?: number;
  termAmount?: number;
  establishmentFee?: number;
  cpo?: number;
  kickback?: number;
  calculatedPrice?: number;
  calculatedPriceShown?: string;
  insuranceType?: InsuranceTypes;
  row?: any;
  renderedCellValue?: any;
  options?: {
    terms?: { label: string; required: boolean }[];
    externalId?: string;
    uiOptions?:{
      [key: string]: string;
    }
  };
  termsOfServiceLink?: string;
  rightOfWithdrawalLink?: string;
  cancellationFormLink?: string;
  priceListLink?: string;
  addons?: ServiceProviderAddOn[];
}

export interface ServiceProviderAddOn {
  id: string;
  title: string;
  logo: string;
  isActive: boolean;
  createdAt: string;
  currency: string;
  price: number;
  unit: string;
  moreInfoLink: string;
  ranking: number;
  cpo: number;
  kickback: number;
  productDescription: string;
  additionalInfo: string;
  icon: string;
  serviceProvider?: ServiceProvider;
}

export interface ParticipantRoleObj {
  id: string;
  role: { [key: string]: any };
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  birthDate: string;
  address: string;
  postalCode: string;
  postArea: string;
  isActive: boolean;
}

export interface Lead {
  id?: string;
  status?: string;
  deliveryStatus?: LeadDeliveryStatus;
  service_provider_id?: string;
  protocol_id?: string;
  createdAt?: string;
  updatedAt?: string;
  deliveredAt?: string;
  value?: any;
  serviceProvider: ServiceProvider;
  protocol?: { [key: string]: any };
  settlement?: { [key: string]: any };
  participantRole: { [key: string]: any };
  protocolAnswer: {
    [K in any]: never;
  };
  author?: LeadAuthor;
  deleted?: boolean;
  consent?: boolean;
  renderedCellValue?: any;
  lastErrorTimestamp?: string;
}

export interface PublicHandoverPageParams {
  hash: string;
  page: string;
  number: string;
  participantId: string;
  roleKey: string;
}

export interface SyncQueueItem {
  assignmentNumber: string;
  handoverProvider?: HandoverProvider;
  handoverDate?: Date;
  status?: SyncItemStatus;
  updatedAt?: Date;
  createdAt?: Date;
  renderedCellValue?: any;
}
