import React from 'react';
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Divider,
  Typography,
} from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { emailValidationRegExp } from 'src/utils/regexes';

const emailRegex = new RegExp(emailValidationRegExp);

interface IProps {
  value: string;
  setValue: (email: string) => void;
  error: string;
  setError: (err: string) => void;
}

const EmailInput: React.FC<IProps> = ({ value, setValue, error, setError }) => {
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetEmail = event.target.value;
    if (!emailRegex.test(targetEmail)) {
      setError('Email error');
    } else {
      setError('');
    }
    setValue(event.target.value);
  };

  return (
    <FormControl error={!!error} fullWidth variant="outlined">
      <OutlinedInput
        value={value}
        onChange={handleEmailChange}
        startAdornment={
          <InputAdornment
            position="start"
            sx={{ display: 'flex', alignItems: 'center', height: 'auto' }}
          >
            <AlternateEmailIcon sx={{ marginRight: '8px', color: 'black' }} />
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                height: '24px',
                margin: '0 8px',
                borderColor: '#E0E0E0',
              }}
            />
          </InputAdornment>
        }
        placeholder="Skriv inn e-postadresse"
        sx={{
          height: '48px',
          fontSize: '16px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E0E0E0',
          },
        }}
        inputProps={{
          pattern: emailValidationRegExp,
        }}
      />
      {error && (
        <Typography color="error" variant="subtitle2">
          {error}
        </Typography>
      )}
    </FormControl>
  );
};

export default EmailInput;
