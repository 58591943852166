import React from 'react';
import {
  MenuItem,
  Select,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Divider,
  Typography,
} from '@mui/material';

interface IProps {
  value: string;
  setValue: (email: string) => void;
  error: string;
  setError: (err: string) => void;
}

const PhoneNumberInput: React.FC<IProps> = ({
  value,
  setValue,
  error,
  setError,
}) => {
  const [countryCode, setCountryCode] = React.useState('+47');

  const handleCountryCodeChange = (event: any) => {
    setCountryCode(event.target.value as string);
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setValue(countryCode + event.target.value);
  };

  return (
    <FormControl fullWidth variant="outlined">
      <OutlinedInput
        type="tel"
        value={value.replace(countryCode, '')}
        onChange={handlePhoneNumberChange}
        startAdornment={
          <InputAdornment
            position="start"
            sx={{ display: 'flex', alignItems: 'center', height: 'auto' }}
          >
            <Select
              value={countryCode}
              onChange={handleCountryCodeChange}
              variant="standard"
              disableUnderline
              sx={{
                fontSize: '16px',
                fontWeight: 500,
                minWidth: '60px',
                paddingRight: '5px',
              }}
            >
              <MenuItem value="+47">+47</MenuItem>
              <MenuItem value="+1">+1</MenuItem>
              <MenuItem value="+44">+44</MenuItem>
            </Select>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                // height: '24px',
                margin: '0 8px',
                borderColor: '#E0E0E0',
              }}
            />
          </InputAdornment>
        }
        placeholder="Skriv inn telefonnummer"
        sx={{
          height: '48px',
          fontSize: '16px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E0E0E0',
          },
        }}
      />
      {error && (
        <Typography color="error" variant="subtitle2">
          {error}
        </Typography>
      )}
    </FormControl>
  );
};

export default PhoneNumberInput;
