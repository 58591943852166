import React, { useState, ReactNode } from 'react';
import { Button, Switch } from '@mui/material';
import PhoneNumberInput from 'src/components/PhoneNumberInput';
import EmailInput from 'src/components/EmailInput';
import { sendVitecWebBrokerNotificationExternal } from 'src/services/api/user';
import { useLocation } from 'react-router-dom';
import { HandoverItemType, ParticipantRole } from 'src/types/handover';
import { MailOutline, Smartphone } from '@mui/icons-material';
import useAsync from 'src/hooks/useAsync';

interface IProps {
  handoverId: string;
  itemType: HandoverItemType;
  role: ParticipantRole;
}

const CustomSwitch = ({
  onChange,
  labels,
  value,
}: {
  onChange: (value: string) => void;
  labels: [{ id: string; label: ReactNode }, { id: string; label: ReactNode }];
  value: string;
}) => {
  const handleEvent = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    onChange(checked ? labels[1].id : labels[0].id);
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
      <span style={{ color: value == labels[0].id ? 'green' : 'inherit' }}>
        {labels[0].label}
      </span>
      <Switch checked={value == labels[1].id} onChange={handleEvent} />
      <span style={{ color: value == labels[1].id ? 'green' : 'inherit' }}>
        {labels[1].label}
      </span>
    </div>
  );
};
const EmailPhoneSendForm = ({ handoverId, itemType, role }: IProps) => {
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const [destinationType, setDestinationType] = useState<'sms' | 'email'>(
    'sms',
  );

  const sendNotification = () => {
    setPhone('');
    setEmail('');
    send(
      { phone, email, role },
      handoverId,
      itemType,
      params.get('token') || '',
      phone ? 'sms' : 'email',
    );
  };
  const { execute: send } = useAsync({
    fn: sendVitecWebBrokerNotificationExternal,
    immediate: false,
    options: {
      toast: {
        useToast: true,
        successMessage: 'Linken sendt',
      }
    }
  });

  return (
    <div style={{ paddingLeft: '24px', paddingRight: '18px' }}>
      <div style={{ display: 'flex', margin: '12px' }}>
        <CustomSwitch
          labels={[
            { id: 'sms', label: <Smartphone /> },
            { id: 'email', label: <MailOutline /> },
          ]}
          onChange={(s) => setDestinationType(s == 'email' ? 'email' : 'sms')}
          value={destinationType}
        />
        {destinationType === 'sms' ? (
          <PhoneNumberInput
            value={phone}
            setValue={setPhone}
            error={error}
            setError={setError}
          />
        ) : (
          <EmailInput
            value={email}
            setValue={setEmail}
            error={error}
            setError={setError}
          />
        )}
      </div>
      <div style={{ marginTop: '17px', textAlign: 'end' }}>
        <Button
          disabled={!!error || (!phone && !email)}
          color="primary"
          variant="contained"
          size="large"
          style={{
            borderRadius: '45px',
            textTransform: 'none',
          }}
          sx={{
            backgroundColor: '#000000',
            color: '#C5FA64',
            '& .Mui-disabled': {
              color: '#787878',
              backgroundColor: '#D1D1D1',
            },
          }}
          onClick={sendNotification}
        >
          Sende
        </Button>
      </div>
    </div>
  );
};

export default EmailPhoneSendForm;
