import ApiService from 'src/services/api/ApiService';
import { HandoverItemType, ParticipantRole } from 'src/types/handover';

export type AdditionalNotificationOptions = {
  emailTemplateId?: string;
};
const api = ApiService.getInstance();

export const list = async () => {
  return await api.get('/user');
};
export const item = async (userId: string) => {
  return await api.get(`/user/${userId}`);
};

export const create = async (data: any) => {
  return await api.post('/user', data);
};

export const update = async (userId: string, data: any) => {
  return await api.patch(`/user/${userId}`, data);
};

export const listByAgency = async (agencyId: string) => {
  return await api.get(`/user/agency/${agencyId}`);
};

export const sendNotification = async (
  participantId: string,
  handoverId: string,
  notificationChannelId: string,
  itemType:
    | HandoverItemType.SETTLEMENT
    | HandoverItemType.PROTOCOL
    | HandoverItemType.LEADS_FLOW
    | HandoverItemType.DYNAMIC,
  additionalNotificationOptions: AdditionalNotificationOptions,
) => {
  return await api.post(`/user/${participantId}/notify`, {
    handoverId,
    itemType,
    notificationChannelId,
    additionalNotificationOptions,
  });
};

export const sendVitecWebBrokerNotification = async (
  participantIds: string[],
  handoverId: string,
  itemType?: HandoverItemType,
  token?: string,
  notificationType?: { [participantId: string]: 'sms' | 'email' },
) => {
  return await api.post(`/user/web-broker-notify/vitec?token=${token}`, {
    participantIds,
    handoverId,
    itemType,
    notificationType,
  });
};

export const sendVitecDynamicFormNotification = async (
  participantIds: string[],
  handoverId: string,
  hash: string,
  token?: string,
  notificationType?: { [participantId: string]: 'sms' | 'email' },
) => {
  return await api.post(`/user/send-out-dynamic-form-notification/vitec?token=${token}`, {
    participantIds,
    handoverId,
    itemType: HandoverItemType.DYNAMIC,
    hash,
    notificationType,
  });
};

export const sendVitecWebBrokerNotificationExternal = async (
  participant: { phone?: string; email?: string; role: ParticipantRole },
  handoverId: string,
  itemType?: HandoverItemType,
  token?: string,
  notificationType?: 'sms' | 'email',
) => {
  return await api.post(
    `/user/web-broker-notify/vitec/external?token=${token}`,
    {
      ...participant,
      handoverId,
      itemType,
      notificationType,
    },
  );
};

export const sendVismaWebBrokerNotification = async (
  participantIds: string[],
  handoverId: string,
  itemType?: HandoverItemType,
  token?: string,
  notificationType?: { [participantId: string]: 'sms' | 'email' },
): Promise<void> => {
  return await api.post(`/user/web-broker-notify/visma?token=${token}`, {
    participantIds,
    handoverId,
    itemType,
    notificationType,
  });
};

export const sendWebBrokerNotification = async (
  participantId: string,
  handoverId: string,
  callBackUrl: string,
  itemType?: HandoverItemType,
) => {
  return await api.post(`/user/${participantId}/web-broker-notify`, {
    handoverId,
    itemType,
    callBackUrl,
  });
};
