import React from 'react';
import { Navigate } from 'react-router';
import App from 'src/App';
import ProtocolRoot from 'src/pages/ProtocolRoot';
import AdminRoot from 'src/pages/AdminRoot';
import PublicRoot from 'src/pages/PublicRoot';
import SendoutRoot from 'src/pages/SendoutRoot';
import adminRoutes from 'src/routes/adminRoutes';
import protocolRoutes from 'src/routes/protocolRoutes';
import publicRoutes from 'src/routes/publicRoutes';
import sendoutRoutes, {
  vitecSendoutRoutes,
  vismaSendoutRoutes,
  dynamicFormSendoutRoutes,
  sendOutNotificationRoutes,
} from 'src/routes/sendoutRoutes';
import settlementRoutes from 'src/routes/settlementRoutes';
import dynamicFormRoutes from 'src/routes/dynamicFormRoutes';
import leadsFlowRoutes from 'src/routes/leadsFlowRoutes';
import VitecSendoutRoot from 'src/pages/VItecSendoutRoot';
import VismaSendoutRoot from 'src/pages/VismaSendoutRoot';
import DynamicFormSendoutView from 'src/pages/sendout/Dynamic/SendoutView';

const rootRoutes: any = [
  {
    Component: App,
    children: [
      {
        path: '/',
        Component: PublicRoot,
        children: publicRoutes,
      },
      {
        path: '/sendout-vitec',
        Component: VitecSendoutRoot,
        children: vitecSendoutRoutes,
      },
      {
        path: '/send-out-notifications',
        Component: VitecSendoutRoot,
        children: sendOutNotificationRoutes,
      },
      {
        path: '/dynamic-send-out',
        Component: DynamicFormSendoutView,
        children: dynamicFormSendoutRoutes,
      },
      {
        path: '/sendout-visma',
        component: VismaSendoutRoot,
        children: vismaSendoutRoutes,
      },
      {
        path: '/sendout',
        Component: SendoutRoot,
        children: sendoutRoutes,
      },
      {
        path: '/admin',
        Component: AdminRoot,
        children: adminRoutes,
      },
      {
        path: '/protocol',
        Component: ProtocolRoot,
        children: protocolRoutes,
      },
      {
        path: '/settlement',
        Component: ProtocolRoot,
        children: settlementRoutes,
      },
      {
        path: '/dynamic',
        Component: ProtocolRoot,
        children: dynamicFormRoutes,
      },
      {
        path: '/leads-flow',
        Component: ProtocolRoot,
        children: leadsFlowRoutes,
      },
      {
        Component: () => <Navigate to="/login" />,
      },
    ],
  },
];

export default rootRoutes;
