import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getHandoverForDynamicFormSendout } from 'src/services/api/publicHandover';
import { HandoverItemType } from 'src/types/handover';
import MainSendOutView from './MainView';
import { sendVitecDynamicFormNotification } from 'src/services/api/user';
import { cancelVitecBrokerNotification } from 'src/services/api/handover';

function SendoutView() {
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const token = params.get('token') || '';
  const { hash } = useParams<{
    hash: string;
  }>();

  const cancelNotification = (
    notificationId: string,
    itemType: HandoverItemType,
  ) => cancelVitecBrokerNotification(notificationId, itemType, token);

  const sendNotification = (
    participantIds: string[],
    handoverId: string,
    itemType?: HandoverItemType,
    destinationType?: { [participantId: string]: 'sms' | 'email' },
  ) =>
    sendVitecDynamicFormNotification(
      participantIds,
      handoverId,
      hash || "", token,
      destinationType,
    );

  const getHandoverForSendoutView = (params: { itemType: HandoverItemType }) =>
    getHandoverForDynamicFormSendout(token, hash || "");

  return (
    <>
      <MainSendOutView
        getHandoverForSendoutView={getHandoverForSendoutView}
        sendWebBrokerNotification={sendNotification}
        cancelBrokerNotification={cancelNotification}
      />
    </>
  );
}

export default SendoutView;
