import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { AppBar, Toolbar, Theme, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import LogoImage from 'src/assets/logo_black.png';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: 0,
    margin: 0,
    backgroundColor: 'white',
    height: '60px',
    boxShadow: '0px 4px 10px 0px #27201D1A',
  },
  flexGrow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  logo: {
    height: '26px',
  },
  helpContainer: {
    position: 'absolute',
    width: '330px',
    right: '16px',
    backgroundColor: 'white',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 10,
  },
}));

const TopBar: React.FunctionComponent = () => {
  const { classes } = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <AppBar className={classes.root} color="primary" position="relative">
      <Toolbar style={{ margin: 0, padding: 0 }}>
        <div style={{ paddingLeft: '30px' }}>
          <img alt="Logo" src={LogoImage} className={classes.logo} />
        </div>
        <div className={classes.flexGrow} />
        <div style={{ position: 'relative', paddingRight: '30px' }}>
          <HelpOutlineIcon
            style={{ cursor: 'pointer', color: '#000000' }}
            onClick={() => setIsHovered(true)}
          />
          {isHovered && (
            <div className={classes.helpContainer}>
              <div>
                <Typography>
                  Need some help? Feel free to contact us!
                </Typography>
                <a href="mailto:support@overo.no">support@overo.no</a>
                <br />
                <a href="tel:+4721619242">+47 21 61 92 42</a>
              </div>
              <div>
                <CancelIcon
                  style={{ cursor: 'pointer' }}
                  color="error"
                  onClick={() => setIsHovered(false)}
                />
              </div>
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
