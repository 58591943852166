import ApiService from 'src/services/api/ApiService';
import { Languages } from 'src/types/locale';
import { HandoverItemType } from 'src/types/handover';
import { VismaSendoutUrlParams } from 'src/pages/sendout/Visma/SendoutView';

const api = ApiService.getInstance();

export const getOneByHash = async (
  hash: string,
  stepId?: string,
  roleKey?: string,
  type?: any,
  language?: Languages,
) => {
  return api.get(
    `/${type ? type : 'protocol'}/${hash}${stepId ? '/' + stepId : ''}${
      (roleKey ? '/' + roleKey : '') + (language ? `?language=${language}` : '')
    }`,
  );
};

export const updateStep = async (
  hash: string,
  stepId: string,
  values: any,
  additionalParams: any,
  roleKey?: any,
  type?: any,
) =>
  api.patch(`/${type ? type : 'protocol'}/${hash}/${stepId}/${roleKey || ''}`, {
    values,
    ...additionalParams,
  });

export const addPersonFunc = async (
  hash: string,
  type: string,
  selectedPerson: string,
) =>
  api.patch(
    `/protocol/${hash}/participant/add-contact-person/${selectedPerson}`,
  );

export const updateStepStatus = async (
  hash: string,
  stepId: string,
  type: string,
  status: string,
) => {
  if (!hash || !stepId || !type || !status) return;
  api.patch(`/${type ? type : 'protocol'}/${hash}/${stepId}/updateStepStatus`, {
    hash: hash,
    stepId: stepId,
    status: status,
  });
};

export const logConsentStatus = async (
  hash: string,
  additionalInfo: any,
  isAgree: boolean,
  type?: string,
) =>
  api.post(`/${type || 'protocol'}/${hash}/log-consent-status`, {
    additionalInfo,
    isAgree,
  });

export const removeParticipant = async (
  hash: string | undefined,
  participantId: string,
  type?: string,
) => {
  if (hash && participantId) {
    api.delete(`/${type ? type : 'protocol'}/${hash}/${participantId}`);
  }
};

export const getSignLink = async (
  hash: string,
  roleKey?: string,
  type?: string,
  participantId?: string,
) =>
  api.get(
    `/${type ? type : 'protocol'}/${hash}/signLink${
      roleKey ? '/' + roleKey : ''
    }${participantId ? '/' + participantId : ''}`,
  );

// the function below is not used anywhere ->
export const getSignLinkWithMode = async (
  hash: string,
  signingMode: string,
  roleKey?: string,
  type?: string,
  participantId?: string,
) => {
  return api.get(
    `/${type ? type : 'protocol'}/${hash}/signLinkWithMode/${
      signingMode ? signingMode : ''
    }/${roleKey || ''}${participantId ? '/' + participantId : ''}`,
  );
};

export const checkSign = async (
  hash: string,
  roleKey?: string,
  type?: string,
  participantId?: string,
) => {
  if (!participantId || participantId === 'undefined') {
    return api.get(
      `/${type ? type : 'protocol'}/${hash}/checkSign/${roleKey || ''}`,
    );
  } else {
    return api.get(
      `/${type ? type : 'protocol'}/${hash}/checkSign/${roleKey || ''}/${
        participantId ? participantId : ''
      }`,
    );
  }
};

export const checkSignWithMode = async (
  hash: string,
  signingMode: string,
  roleKey?: string,
  type?: string,
  participantId?: string,
) => {
  if (!participantId || participantId === 'undefined') {
    return api.get(
      `/${type ? type : 'protocol'}/${hash}/checkSignWithMode/${
        signingMode ? signingMode : ''
      }/${roleKey || ''}`,
    );
  } else {
    return api.get(
      `/${type ? type : 'protocol'}/${hash}/checkSignWithMode/${
        signingMode ? signingMode : ''
      }/${roleKey || ''}/${participantId ? participantId : ''}/${
        signingMode ? signingMode : ''
      }`,
    );
  }
};
enum FlowType {
  settlement = 'settlement',
  protocol = 'protocol',
}
export const getServiceProviders = async ({
  hash,
  type,
}: {
  hash: string;
  type: string;
}) => api.get(`/protocol/${hash}/service-providers/${type}`);

export const getLeadsFlowServiceProviders = async ({
  type,
  agencyId,
}: {
  type: string;
  agencyId?: string;
}) =>
  api.get(
    `handovers/list-leads-flow/service-providers/${type}/${agencyId || ''}`,
  );

export const getLFServiceProviders = async ({
  agencyId,
  selectedLFConfig,
}: {
  agencyId?: string;
  selectedLFConfig?: string[];
}) =>
  api.post(`handovers/list-leads-flow/service-providers/${agencyId || ''}`, {
    selectedLFConfig,
  });

export const getLeadsFlowServiceProvidersPublic = async ({
  type,
  agencyId,
  hash,
}: {
  type: string;
  agencyId?: string;
  hash?: string;
}) =>
  api.get(
    `handovers/list-leads-flow/service-providers/public/${type}/${
      agencyId || ''
    }/${hash || ''}`,
  );

export const getBank = async ({ number }: { number: string }) =>
  api.get(`/banks/${number}`);

export const getCity = async ({ postcode }: { postcode: string }) =>
  api.get(`/cities/${postcode}`);

export const getOneForSettlementSendout = async ({
  assignmentNumber,
}: {
  assignmentNumber: string;
}) => api.get(`/settlement?assignmentNumber=${assignmentNumber}`);

export const getVitecOneForSettlementSendout = async ({
  token,
}: {
  token: string;
}) => api.get(`/settlement/vitec?token=${token}`);

export const getVitecOneForSendOut = async (
  token: string,
  itemType: HandoverItemType,
) => api.get(`/${itemType}/vitec?token=${token}`);

export const getVismaOneForSendOut = async (
  urlParams: VismaSendoutUrlParams,
  itemType: HandoverItemType,
) => {
  const queryString = new URLSearchParams(Object(urlParams)).toString();
  return await api.get(`${itemType}/visma?${queryString}`);
};

export const getHandoverForDynamicFormSendout = async (
  token: string,
  hash: string,
) => api.get(`/dynamic/vitec?token=${token}&hash=${hash}`);

export const getOneForProtocolSendout = async ({
  assignmentNumber,
}: {
  assignmentNumber?: string;
  token?: string;
}) => api.get(`/protocol?assignmentNumber=${assignmentNumber}`);

export const copyHandover = async ({
  handoverId,
  params,
}: {
  handoverId: string;
  params: object;
}) => {
  return api.post(`/handovers/${handoverId}/duplicate`, params);
};
