import * as React from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import { UiOptions } from 'src/pages/PublicHandover/context/agency';

export const enum agencyStyleEnum {
  BACKGROUND_COLOR = 'var(--background-color)',
  TEXT_COLOR = 'var(--text-color)',
  BUTTON_TEXT_COLOR = 'var(--button-text-color)',
  BUTTON_BACKGROUND_COLOR = 'var(--button-background-color)',
  ACTION_BACKGROUND_COLOR = 'var(--action-background-color)',
  ACTION_BUTTON_BACKGROUND_COLOR = 'var(--action-button-background-color)',
  PROVIDER_BACKGROUND_COLOR = 'var(--provider-background-color)',
  PAGE_BACKGROUND_COLOR = 'var(--page-background-color)',
  LIST_ICON_COLOR = 'var(--list-icon-color)',
  CARD_BACKGROUND_COLOR = 'var(--card-background-color)',
  PAGE_MAIN_BACKGROUND_COLOR = 'var(--page-main-background-color)',
  RADIO_BUTTON_COLOR = 'var(--radio-button-color)',
  CARD_TEXT_COLOR = 'var(--card-text-color)',
  CARD_HEADER_COLOR = 'var(--card-header-color)',
  PROVIDER_CARD_TEXT_COLOR = 'var(--provider-card-text-color)',
  HOME_CONTENT_COLOR = 'var(--home-content-color)',
  PROVIDER_LIST_BACKGROUND_COLOR = 'var(--provider-list-color)',
  PROVIDER_CARD_NOBUTTON_BACKGROUND_COLOR = 'var(--provider-list-nobutton-background-color)',
  PROVIDER_CARD_NOBUTTON_BUTTON_TEXT_COLOR = 'var(--provider-list-nobutton-button-text-color)',
  CARD_ICON_COLOR = 'var(--card-icon-color)',
  CARD_ICON_BACKGROUND_COLOR = 'var(--card-icon-background-color)',
}

const styles = (uiOptions: UiOptions | null) => ({
  html: {
    '--background-color': uiOptions?.backgroundColor || '#fff',
    '--text-color': uiOptions?.textColor || '#031821',
    '--button-text-color': uiOptions?.buttonTextColor || '#fff',
    '--button-background-color': uiOptions?.buttonBackgroundColor || '#031821',
    '--action-background-color':
      uiOptions?.actionButtonBackgroundColor || '#61B946',
    '--action-button-background-color':
      uiOptions?.actionButtonBackgroundColor || '#61B946',
    '--provider-background-color':
      uiOptions?.providerBackgroundColor || '#E2EAE4',
    '--page-background-color': uiOptions?.pageBackgroundColor || '#0A1018',
    '--list-icon-background-color':
      uiOptions?.listIconBackgroundColor || '#ECEAE2',
    '--list-icon-color': uiOptions?.listIconColor || '#0A1018',
    '--card-icon-background-color':
      uiOptions?.cardIconBackgroundColor || '#0A1018',
    '--card-icon-color': uiOptions?.cardIconColor || '#fff',
    '--card-background-color': uiOptions?.cardBackgroundColor || '#ECEAE2',
    '--card-header-color': uiOptions?.cardHeaderColor || '#fff',
    '--card-text-color': uiOptions?.cardTextColor || '#0A1018',
    '--home-content-color': uiOptions?.homeContentColor || '#fff',
    '--radio-button-color': uiOptions?.radioButtonColor || '#DEB8A6',
    '--provider-list-color':
      uiOptions?.providerListBackgroundColor || '#0A1018',
    '--provider-card-text-color': uiOptions?.providerCardTextColor || '#0A1018',
    '--provider-card-background-color':
      uiOptions?.providerCardBackgroundColor || '#fff',
    '--provider-list-nobutton-background-color':
      uiOptions?.providerCardNoButtonBackground || '#fff',
    '--provider-list-nobutton-button-text-color':
      uiOptions?.providerCardNoButtonButtonTextColor || '#fff',
    '--page-main-background-color':
      uiOptions?.pageMainBackgroundColor || '#fff',
    'image-swiper-text-color': uiOptions?.imageSwiperTextColor || '#fff',
  },
});

export const CustomGlobalStyles = ({
  uiOptions,
}: {
  uiOptions?: UiOptions;
}) => <GlobalStyles styles={styles(uiOptions || null)} />;
