import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { HandoverItemType, Participant, ProtocolNotifications } from 'src/types/handover';
import { PROTOCOL_NAMESPACE } from 'src/i18n';
import useAsync from 'src/hooks/useAsync';

interface IProps {
  participants: Participant[];
  publicLink: string;
  handoverId: string;
  currentTab?: string;
  handleRefresh: () => void;
  sendWebBrokerNotification: (
    participantIds: string[],
    handoverId: string,
    itemType?: HandoverItemType,
    destinationType?: { [participantId: string]: 'sms' | 'email' }
  ) => Promise<unknown>;
  addNotifications: (notifications: ProtocolNotifications[]) => void;
  switchTab: 'sellers' | 'buyers' | 'participants';
}

const ParticipantsTable = ({
  participants,
  publicLink,
  handoverId,
  currentTab,
  handleRefresh,
  sendWebBrokerNotification,
  addNotifications,
  switchTab,
}: IProps) => {
  const { t } = useTranslation(PROTOCOL_NAMESPACE);
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>(
    [],
  );
  const [selectParticipantsText, setSelectParticipantsText] = useState<string>("");
  const [destinationType, setDestinationType] = useState<{
    [participantId: string]: 'email' | 'sms';
  }>({});

  useEffect(() => {
    setDestinationType(
      participants.reduce(
        (acc, p) => ({
          ...acc,
          [p.id]: destinationType[p.id] || 'sms',
        }),
        {},
      ),
    );
  }, [currentTab, participants]);

  useEffect(() => {
    setSelectedParticipants([]);

    switch (switchTab) {
      case 'sellers':
        setSelectParticipantsText(t('Velg selger(e)'));
        break;
      case 'buyers':
        setSelectParticipantsText(t('Velg kjoper(e)'));
        break;
      case 'participants':
        setSelectParticipantsText(t('Velg deltager(e)'));
        break;
      default:
        setSelectParticipantsText(t('Velg deltager(e)'));
    }
  }, [switchTab]);

  const { execute: executeSendNotification, isLoading: isLoadingSend } = useAsync({
    fn: sendWebBrokerNotification,
    options: {
      successCb: (params) => {
        addNotifications(params.sms || []);
        addNotifications(params.email || []);
        setSelectedParticipants([]);
      },
      toast: {
        useToast: true,
        successMessage: `Notification${
          selectedParticipants.length > 1 ? 's' : ''
        } has been sent`,
      },
    },
  });

  const sendNotification = async () => {
    const destinations: {
      [participantId: string]: 'email' | 'sms';
    } = {};

    for (const id of Object.keys(destinationType)) {
      if (selectedParticipants.includes(id)) {
        destinations[id] = destinationType[id];
      }
    }

    await executeSendNotification(
      selectedParticipants,
      handoverId,
      currentTab,
      destinations,
    );
    await handleRefresh();
  };

  const onSelectAllClick = () => {
    if (selectedParticipants.length) {
      setSelectedParticipants([]);
    } else {
      setSelectedParticipants(participants.map((p) => p.id));
    }
  };

  const isSelected = (id: string) => {
    return selectedParticipants.indexOf(id) > -1;
  };

  const onItemSelect = (id: string) => {
    if (isSelected(id)) {
      setSelectedParticipants((prevState) => prevState.filter((p) => p !== id));
    } else {
      setSelectedParticipants((prevState) => [...prevState, id]);
    }
  };

  return (
    <div
      style={{
        paddingLeft: '18px',
        paddingRight: '24px',
        borderRight: '1px solid #e0e0e0',
      }}
    >
      <Typography variant="h6">{selectParticipantsText}</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checkedIcon={
                    <CheckBoxOutlinedIcon style={{ color: '#588504' }} />
                  }
                  checked={participants.length === selectedParticipants.length}
                  onChange={onSelectAllClick}
                />
              </TableCell>
              <TableCell>Navn</TableCell>
              <TableCell>Mobilnummer</TableCell>
              <TableCell>E-post</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participants.map((item) => {
              const fullName = `${item.firstName} ${item.lastName}`;
              return (
                <TableRow key={item.id}>
                  <TableCell
                    style={{ borderBottom: 'none' }}
                    padding="checkbox"
                  >
                    <Checkbox
                      color="primary"
                      checkedIcon={
                        <CheckBoxOutlinedIcon style={{ color: '#588504' }} />
                      }
                      checked={isSelected(item.id)}
                      onChange={() => onItemSelect(item.id)}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>
                    {fullName}
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>
                    {item.phone}
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>
                    {item.email}
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>
                    <div>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="sms"
                          control={
                            <Radio
                              checked={destinationType[item.id] === 'sms'}
                              value={destinationType}
                              sx={{
                                '&.Mui-checked': {
                                  color: '#75AA15',
                                },
                              }}
                              onChange={() =>
                                setDestinationType((prevState) => ({
                                  ...prevState,
                                  [item.id]: 'sms',
                                }))
                              }
                            />
                          }
                          label="SMS"
                        />
                        <FormControlLabel
                          value="email"
                          control={
                            <Radio
                              checked={destinationType[item.id] === 'email'}
                              value={destinationType}
                              sx={{
                                '&.Mui-checked': {
                                  color: '#75AA15',
                                },
                              }}
                              onChange={() =>
                                setDestinationType((prevState) => ({
                                  ...prevState,
                                  [item.id]: 'email',
                                }))
                              }
                            />
                          }
                          label="E-post"
                        />
                      </RadioGroup>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '24px',
          marginBottom: '24px',
        }}
      >
        <Button
          variant="outlined"
          href={publicLink}
          target="_blank"
          style={{
            marginRight: '18px',
            borderRadius: '45px',
            // borderRadius: '18px',
            borderColor: '#000000',
            color: '#000000',
            textTransform: 'none',
          }}
        >
          {t('common.openLink')}
        </Button>
        <Button
          disabled={selectedParticipants.length === 0}
          color="primary"
          variant="contained"
          size="large"
          style={{
            borderRadius: '45px',
            textTransform: 'none',
          }}
          sx={{
            backgroundColor: '#000000',
            color: '#C5FA64',
            '& .Mui-disabled': {
              color: '#787878',
              backgroundColor: '#D1D1D1',
            },
            '&:hover': {
              backgroundColor: '#000000',
            },
          }}
          onClick={sendNotification}
        >
          {t('buttons.send')}
        </Button>
      </div>
    </div>
  );
};

export default ParticipantsTable;
