import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import TopBar from 'src/pages/sendout/common/TopBar';
import CustomError from 'src/pages/sendout/common/Error';
import {
  HandoverItemType,
  Participant,
  ParticipantRole,
  ProtocolNotifications,
} from 'src/types/handover';
import { useParams } from 'react-router-dom';
import useAsync from 'src/hooks/useAsync';
import { useTranslation } from 'react-i18next';
import { getProtocolTranslation } from 'src/services/api/locales';
import { PROTOCOL_NAMESPACE } from 'src/i18n';
import ParticipantsTable from 'src/pages/sendout/common/ParticipantsTable';
import ParticipantsTableMobile from 'src/pages/sendout/common/PatricipantsTableMobile';
import NotificationsTable from 'src/pages/sendout/common/NotificationsTable';
import NotificationsTableMobile from 'src/pages/sendout/common/NotificationsTableMobile';
import { useStyles } from '../common/MainView';

dayjs.extend(utc);

export interface MainViewProps {
  getHandoverForSendoutView: (immediateParams: {
    itemType: HandoverItemType;
  }) => Promise<unknown>;

  sendWebBrokerNotification: (
    participantIds: string[],
    handoverId: string,
    itemType?: HandoverItemType,
    destinationType?: { [participantId: string]: 'sms' | 'email' },
  ) => Promise<unknown>;

  cancelBrokerNotification: (
    notificationId: string,
    itemType: HandoverItemType,
  ) => Promise<unknown>;
}

function MainSendOutView(props: MainViewProps) {
  const {
    getHandoverForSendoutView,
    sendWebBrokerNotification,
    cancelBrokerNotification,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(true);
  const [locale, setLocale] = useState('no');
  const [publicLink, setPublicLink] = useState<string>('');

  const [tabs, setTabs] = useState<any[]>([]);
  
  const { classes } = useStyles({ isMobile })();
  const { id, itemType: currentTab } = useParams<{
    id: string;
    itemType: HandoverItemType;
  }>();

  const handoverItemType = HandoverItemType.DYNAMIC;

  const [itemData, setItemData] = useState<{
    itemType: HandoverItemType;
    handoverId: string;
    participants: Participant[];
    publicLinks: string[];
  }>({
    itemType: HandoverItemType.DYNAMIC,
    handoverId: '',
    participants: [],
    publicLinks: [],
  });

  const { i18n, t } = useTranslation(PROTOCOL_NAMESPACE);

  useEffect(() => {
    // it doesn't make sense to use another dictionary for settlement name space here
    getProtocolTranslation(locale, true).then((data) => {
      i18n.addResourceBundle(locale, PROTOCOL_NAMESPACE, data);
      i18n.changeLanguage(locale);
    });
  }, [i18n, locale]);

  const addNotifications = (notifications: ProtocolNotifications[]) => {
    const participantNotifications = notifications.filter(
      (n) => n.participant?.role.key === ParticipantRole.BUYER,
    );
    setItemData((prevState) => ({
      ...prevState,
      participants: prevState.participants.map((s) => ({
        ...s,
        notifications: participantNotifications.filter((pn) => pn.id === s.id),
      })),
    }));
  };

  const handleRefresh = useCallback(() => {
    getInfo({
      itemType: currentTab,
    });
  }, [currentTab]);

  const onLoad = useCallback(
    (data: any) => {
      setTabs([{
        value: data.dynamicForm?.title,
        label: data.dynamicForm?.title,
      }]);

      const newLocale = data.handover?.agency?.country?.defaultLanguage;

      if (newLocale && newLocale !== locale) {
        setLocale(newLocale);
      }
      const participants = data.participants;

      const link = `/dynamic/${data?.hash}`;
      setItemData({
        itemType: HandoverItemType.DYNAMIC,
        handoverId: data?.handover?.id,
        publicLinks: [link],
        participants,
      });

      setPublicLink(link);
      setLoading(false);
    },
    [currentTab],
  );

  const notificationsMemoized = useMemo(() => {
    let notificationsArray: ProtocolNotifications[] = [];
    for (const participant of itemData.participants) {
      notificationsArray = notificationsArray.concat(
        participant?.notifications?.map((n) => ({
          ...n,
          participant,
        })) || [],
      );
    }
    return notificationsArray;
  }, [itemData.participants]);

  const { error, execute: getInfo } = useAsync({
    fn: getHandoverForSendoutView,
    immediate: true,
    immediateParams: {
      itemType: currentTab,
    },
    options: {
      successCb: onLoad,
      toast: {
        useToast: true,
        successMessage: `Updated information`,
      }
    },
    deps: [currentTab]
  });

  const { execute: cancel } = useAsync({
    fn: cancelBrokerNotification,
    options: { successCb: handleRefresh },
    deps: [currentTab],
  });

  if (error) {
    return <CustomError error={error} />;
  }

  return (
    <>
      <TopBar />
      <Divider className={classes.divider} />
      <div className={classes.tabsWrapper}>
        <Tabs
          className={classes.mainTabs}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#86CB07',
            },
          }}
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
        >
          {tabs?.map((tab) => (
            <Tab
              className={classes.tab}
              style={{
                color: '#000000',
                ...(isMobile ? { fontSize: '12px' } : {}),
              }}
              key={tab.value}
              label={t(tab.label)}
              value={tab.value}
            />
          ))}
        </Tabs>
        <div style={{ width: '100%', textAlign: 'end' }}>
          <Button
            className={classes.refreshButton}
            variant="outlined"
            color="primary"
            onClick={handleRefresh}
          >
            <span>{t('buttons.refresh')}</span>
          </Button>
        </div>
      </div>
      <Grid container className={classes.root}>
        {loading ? (
          <Grid item xs={12} className={classes.center}>
            <CircularProgress className={classes.spinnerColor} />
          </Grid>
        ) : (
          <>
            <Paper
              style={{
                width: 'calc(100% - 60px)',
                marginLeft: '30px',
                marginRight: '30px',
              }}
              square={false}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '12px 18px',
                  backgroundColor: '#F9F9F9',
                }}
              >
                <div>
                  <Typography variant="h4">Utsendelse</Typography>
                </div>
              </div>
              <Grid container>
                <Grid item xs={12} md={7} lg={7}>
                  {isMobile ? (
                    <ParticipantsTableMobile
                      sendWebBrokerNotification={sendWebBrokerNotification}
                      addNotifications={addNotifications}
                      participants={itemData.participants}
                      switchTab={'participants'}
                      publicLink={publicLink}
                      handoverId={itemData.handoverId}
                      currentTab={currentTab}
                      handleRefresh={handleRefresh}
                    />
                  ) : (
                    <ParticipantsTable
                      sendWebBrokerNotification={sendWebBrokerNotification}
                      addNotifications={addNotifications}
                      participants={itemData.participants}
                      switchTab={'participants'}
                      publicLink={publicLink}
                      handoverId={itemData.handoverId}
                      currentTab={currentTab}
                      handleRefresh={handleRefresh}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={5} lg={5}>
                  {/*TODO: Wait until email notification channel will be ready*/}
                  {/*<EmailSendForm destinationType={destinationType} />*/}
                </Grid>
              </Grid>
            </Paper>
            <Paper
              style={{
                ...(isMobile
                  ? { width: '100%' }
                  : {
                      width: 'calc(100% - 60px)',
                      marginLeft: '30px',
                      marginRight: '30px',
                    }),
                marginTop: '18px',
              }}
              square={false}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '12px 18px',
                  backgroundColor: '#F9F9F9',
                }}
              >
                <div>
                  <Typography variant="h4">Tidligere utsendelser</Typography>
                </div>
              </div>
              <div>
                {isMobile ? (
                  <NotificationsTableMobile
                    notifications={notificationsMemoized}
                    itemData={itemData}
                    cancelNotification={(id) => cancel(id, handoverItemType)}
                  />
                ) : (
                  <NotificationsTable
                    notifications={notificationsMemoized}
                    itemData={itemData}
                    cancelNotification={(id) => cancel(id, handoverItemType)}
                  />
                )}
              </div>
            </Paper>
          </>
        )}{' '}
      </Grid>
    </>
  );
}

export default MainSendOutView;
