import React from 'react';
import { lazy } from 'react';
import { Navigate } from 'react-router';

const sendoutRoutes = [
  {
    path: '/sendout/protocol/:roleKey?',
    Component: lazy(() => import('src/pages/sendout/Protocol')),
    isPublic: true,
  },
  {
    path: '/sendout/settlement/:roleKey?',
    Component: lazy(() => import('src/pages/sendout/Settlement')),
    isPublic: true,
  },
  {
    Component: () => <Navigate to="/404" />,
  },
];

export const sendOutNotificationRoutes = [
  {
    path: '/send-out-notifications/:itemType',
    Component: lazy(() => import('src/pages/sendout/VITEC/SendoutView')),
    isPublic: true,
  },
  {
    Component: () => <Navigate to="/404" />,
  },
];

export const vitecSendoutRoutes = [
  {
    path: '/sendout-vitec/:itemType',
    Component: lazy(() => import('src/pages/sendout/VITEC/SendoutView')),
    isPublic: true,
  },
  {
    Component: () => <Navigate to="/404" />,
  },
];

export const vismaSendoutRoutes = [
  {
    path: '/sendout-visma/:itemType',
    Component: lazy(() => import('src/pages/sendout/Visma/SendoutView')),
    isPublic: true,
  },
  {
    Component: () => <Navigate to="/404" />,
  },
];

export const dynamicFormSendoutRoutes = [
  {
    path: '/dynamic-send-out/:hash',
    Component: lazy(() => import('src/pages/sendout/Dynamic/SendoutView')),
    isPublic: true,
  },
  {
    Component: () => <Navigate to="/404" />,
  },
];

export default sendoutRoutes;
