import { NotificationStatus } from 'src/types/handover';

export const translateStatus = (status: NotificationStatus): string => {
  switch (status) {
    case NotificationStatus.DELIVERED:
      return 'sendt';
    case NotificationStatus.CANCELED:
      return 'kansellert';
    case NotificationStatus.FAILED:
      return 'feilet';
    case NotificationStatus.SCHEDULED:
      return 'planlagt';
    default:
      return 'ukjent status';
  }
};

export const statusToColor = (status: NotificationStatus): string => {
  switch (status) {
    case NotificationStatus.CANCELED:
      return '#FFECEB';
    case NotificationStatus.DELIVERED:
      return '#DCF9EC';
    default:
      return '';
  }
};
