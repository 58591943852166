import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  CircularProgress,
  colors,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import TopBar from 'src/pages/sendout/common/TopBar';
import CustomError from 'src/pages/sendout/common/Error';
import {
  HandoverItemType,
  Participant,
  ParticipantRole,
  ProtocolNotifications,
} from 'src/types/handover';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useAsync from 'src/hooks/useAsync';
import { useTranslation } from 'react-i18next';
import { getProtocolTranslation } from 'src/services/api/locales';
import { PROTOCOL_NAMESPACE } from 'src/i18n';
import { agencyStyleEnum } from 'src/pages/PublicHandover/helpers/globalStyles';
import RoleSwitch from 'src/components/RoleSwitch';
import ParticipantsTable from 'src/pages/sendout/common/ParticipantsTable';
import ParticipantsTableMobile from 'src/pages/sendout/common/PatricipantsTableMobile';
import NotificationsTable from 'src/pages/sendout/common/NotificationsTable';
import NotificationsTableMobile from 'src/pages/sendout/common/NotificationsTableMobile';
import EmailSendForm from 'src/pages/sendout/common/EmailSendForm';

dayjs.extend(utc);

const keysMap = {
  [HandoverItemType.SETTLEMENT]: {
    publicLinks: 'settlementLinks',
    participants: 'settlementParticipants',
  },
  [HandoverItemType.PROTOCOL]: {
    publicLinks: 'protocolLinks',
    participants: 'participants',
  },
};

const tabs = [
  { value: 'settlement', label: 'common.settlement' },
  { value: 'protocol', label: 'common.protocol' },
];

export const useStyles = ({ isMobile }: { isMobile: boolean }) =>
  makeStyles()((theme: Theme) => ({
    root: {
      marginTop: '18px',
      height: 'calc(100vh - 208px)',
    },
    topWrapper: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      '& button': {
        margin: theme.spacing(2),
      },
      '& > *': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
      },
    },
    center: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    spinnerColor: {
      '& *': {
        color: agencyStyleEnum?.TEXT_COLOR,
      },
    },
    cell: {
      height: '100%',
    },
    mainTabs: {
      width: '100%',
    },
    divider: {
      backgroundColor: colors.grey[300],
    },
    content: {
      marginTop: theme.spacing(3),
    },
    selectRoot: {
      '&:before': {
        border: 'none',
      },
    },
    refreshButton: {
      borderRadius: '18px',
      borderColor: '#000000',
      color: '#000000',
      textTransform: 'none',
      marginTop: isMobile ? '16px' : 0,
      marginBottom: isMobile ? '16px' : 0,
      ...(isMobile ? { width: '100%' } : {}),
    },
    tabsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '6px 30px',
      paddingBottom: 0,
      paddingTop: 0,
      backgroundColor: '#FAFFF0',
      alignItems: 'center',
      flexDirection: isMobile ? 'column' : 'row',
    },
    tab: {
      ...(isMobile ? { fontSize: '14px' } : {}),
      color: '#000000',
      '& .MuiTabs-indicator': {
        borderBottom: '1px solid #86CB07',
      },
    },
  }));

export interface MainViewProps {
  getHandoverForSendoutView: (immediateParams: {
    itemType: HandoverItemType;
  }) => Promise<unknown>;

  sendWebBrokerNotification: (
    participantIds: string[],
    handoverId: string,
    itemType?: HandoverItemType,
    destinationType?: { [participantId: string]: 'sms' | 'email' },
  ) => Promise<unknown>;

  cancelBrokerNotification: (
    notificationId: string,
    itemType: HandoverItemType,
  ) => Promise<unknown>;
}

function MainSendOutView({
  getHandoverForSendoutView,
  sendWebBrokerNotification,
  cancelBrokerNotification,
}: MainViewProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(true);
  const [locale, setLocale] = useState('no');
  const [switchTab, setSwitchTab] = useState<'sellers' | 'buyers'>('sellers');
  const { search, pathname } = useLocation();
  const [hasSettlement, setHasSettlement] = useState(true);
  const { classes } = useStyles({ isMobile })();
  const { id, itemType: currentTab } = useParams<{
    id: string;
    itemType: HandoverItemType;
  }>();

  const handoverItemType: HandoverItemType =
    currentTab ?? HandoverItemType.SETTLEMENT;
  const [itemData, setItemData] = useState<{
    itemType: HandoverItemType;
    handoverId: string;
    sellers: Participant[];
    buyers: Participant[];
    publicLinks: string[];
  }>({
    itemType: HandoverItemType.SETTLEMENT,
    handoverId: '',
    sellers: [],
    buyers: [],
    publicLinks: [],
  });
  const { i18n, t } = useTranslation(PROTOCOL_NAMESPACE);
  useEffect(() => {
    // it doesn't make sense to use another dictionary for settlement name space here
    getProtocolTranslation(locale, true).then((data) => {
      i18n.addResourceBundle(locale, PROTOCOL_NAMESPACE, data);
      i18n.changeLanguage(locale);
    });
  }, [i18n, locale]);

  const addNotifications = (notifications: ProtocolNotifications[]) => {
    const buyerNotifications = notifications.filter(
      (n) => n.participant?.role.key === ParticipantRole.BUYER,
    );
    const sellerNotifications = notifications.filter(
      (n) => n.participant?.role.key === ParticipantRole.SELLER,
    );
    setItemData((prevState) => ({
      ...prevState,
      sellers: prevState.sellers.map((s) => ({
        ...s,
        notifications: sellerNotifications.filter((sn) => sn.id === s.id),
      })),
      buyers: prevState.buyers.map((s) => ({
        ...s,
        notifications: buyerNotifications.filter((bn) => bn.id === s.id),
      })),
    }));
  };

  const handleTabsChange = useCallback(
    (event?: any, itemType?: string) => {
      const pathWithoutTabNameRegExp = new RegExp(
        `${HandoverItemType.SETTLEMENT}|${HandoverItemType.PROTOCOL}`,
        'gi',
      );
      navigate(
        `${pathname.replace(pathWithoutTabNameRegExp, '')}${itemType}${search}`,
      );
    },
    [id],
  );

  const handleRefresh = useCallback(() => {
    getInfo({
      itemType: currentTab,
    });
  }, [currentTab]);

  const onLoad = useCallback(
    (data: any) => {
      const itemType =
        currentTab === HandoverItemType.PROTOCOL
          ? HandoverItemType.PROTOCOL
          : HandoverItemType.SETTLEMENT;

      if (data) {
        const item = data[itemType];
        if (!item && itemType === HandoverItemType.SETTLEMENT && !data.settlement) {
          // Only redirects if there's no settlement data at all
          navigate(
            `${pathname.replace(/settlement|protocol/gi, '')}${
              HandoverItemType.PROTOCOL
            }${search}`,
            { replace: true }
          );
          return;
        }
        const newLocale = item.handover?.agency?.country?.defaultLanguage;
        if (newLocale && newLocale !== locale) {
          setLocale(newLocale);
        }
        let participants = (item && item[keysMap[itemType].participants]) || [];
        if (
          participants.length === 0 &&
          itemType === HandoverItemType.SETTLEMENT
        ) {
          participants = item[keysMap[HandoverItemType.PROTOCOL].participants];
        }
        const sellers = participants.filter(
          (participant: any) => participant.role === ParticipantRole.SELLER,
        );
        const buyers = participants.filter(
          (participant: any) => participant.role === ParticipantRole.BUYER,
        );

        setItemData({
          itemType,
          handoverId: item?.handover?.id,
          publicLinks: (item && item[keysMap[itemType].publicLinks]) || [],
          sellers,
          buyers,
        });
        if (currentTab === HandoverItemType.PROTOCOL) {
          setHasSettlement(!!item.handover?.settlement);
        }
      }
      setLoading(false);
    },
    [currentTab],
  );

  const tabsMemoized = useMemo(() => {
    if (!hasSettlement) {
      return tabs.filter((t) => t.value !== 'settlement');
    }
    return tabs;
  }, [hasSettlement]);

  const notificationsMemoized = useMemo(() => {
    let notificationsArray: ProtocolNotifications[] = [];
    for (const seller of itemData.sellers) {
      notificationsArray = notificationsArray.concat(
        seller?.notifications?.map((n) => ({
          ...n,
          participant: seller,
        })) || [],
      );
    }

    for (const buyer of itemData.buyers) {
      notificationsArray = notificationsArray.concat(
        buyer?.notifications?.map((n) => ({
          ...n,
          participant: buyer,
        })) || [],
      );
    }

    return notificationsArray;
  }, [itemData.sellers, itemData.buyers]);

  const { error, execute: getInfo } = useAsync({
    fn: getHandoverForSendoutView,
    immediate: true,
    immediateParams: {
      itemType: currentTab,
    },
    options: {
      successCb: onLoad,
      toast: {
        useToast: true,
        successMessage: `Updated information`,
      }
    },
    deps: [currentTab],
  });

  const { execute: cancel } = useAsync({
    fn: cancelBrokerNotification,
    options: { successCb: handleRefresh },
    deps: [currentTab],
  });

  const resultRole =
    switchTab === 'buyers' ? ParticipantRole.BUYER : ParticipantRole.SELLER;

  const publicLink = useMemo(() => {
    const link: any = itemData.publicLinks.find(
      // @ts-ignore
      (link) => link.role === resultRole,
    );
    return `/${itemData.itemType}/${link?.hash}`;
  }, [itemData.publicLinks, resultRole]);

  if (error) {
    return <CustomError error={error} />;
  }

  return (
    <>
      <TopBar />
      <Divider className={classes.divider} />

      <div className={classes.tabsWrapper}>
        <Tabs
          className={classes.mainTabs}
          onChange={handleTabsChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#86CB07',
            },
          }}
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
        >
          {tabsMemoized.map((tab) => (
            <Tab
              className={classes.tab}
              style={{
                color: '#000000',
                ...(isMobile ? { fontSize: '12px' } : {}),
              }}
              key={tab.value}
              label={t(tab.label)}
              value={tab.value}
            />
          ))}
        </Tabs>
        <div style={{ width: '100%', textAlign: 'end' }}>
          <Button
            className={classes.refreshButton}
            variant="outlined"
            color="primary"
            onClick={handleRefresh}
          >
            <span>{t('buttons.refresh')}</span>
          </Button>
        </div>
      </div>
      <Grid container className={classes.root}>
        {loading ? (
          <Grid item xs={12} className={classes.center}>
            <CircularProgress className={classes.spinnerColor} />
          </Grid>
        ) : (
          <>
            <Paper
              style={{
                width: 'calc(100% - 60px)',
                marginLeft: '30px',
                marginRight: '30px',
              }}
              square={false}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '15px',
                  padding: '12px 18px',
                  backgroundColor: '#F9F9F9',
                }}
              >
                <div>
                  <Typography variant="h4">Utsendelse</Typography>
                </div>
                <div>
                  <RoleSwitch value={switchTab} onChange={setSwitchTab} />
                </div>
              </div>
              <Grid container>
                <Grid item xs={12} md={7} lg={7}>
                  {isMobile ? (
                    <ParticipantsTableMobile
                      sendWebBrokerNotification={sendWebBrokerNotification}
                      addNotifications={addNotifications}
                      participants={itemData[switchTab]}
                      switchTab={switchTab}
                      publicLink={publicLink}
                      handoverId={itemData.handoverId}
                      currentTab={currentTab}
                      handleRefresh={handleRefresh}
                    />
                  ) : (
                    <ParticipantsTable
                      sendWebBrokerNotification={sendWebBrokerNotification}
                      addNotifications={addNotifications}
                      participants={itemData[switchTab]}
                      switchTab={switchTab}
                      publicLink={publicLink}
                      handoverId={itemData.handoverId}
                      currentTab={currentTab}
                      handleRefresh={handleRefresh}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={5} lg={5}>
                  <EmailSendForm
                    handoverId={itemData.handoverId}
                    itemType={itemData.itemType}
                    role={resultRole}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper
              style={{
                ...(isMobile
                  ? { width: '100%' }
                  : {
                      width: 'calc(100% - 60px)',
                      marginLeft: '30px',
                      marginRight: '30px',
                    }),
                marginTop: '18px',
              }}
              square={false}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '12px 18px',
                  backgroundColor: '#F9F9F9',
                }}
              >
                <div>
                  <Typography variant="h4">Tidligere utsendelser</Typography>
                </div>
              </div>
              <div>
                {isMobile ? (
                  <NotificationsTableMobile
                    notifications={notificationsMemoized}
                    itemData={itemData}
                    cancelNotification={(id) => cancel(id, handoverItemType)}
                  />
                ) : (
                  <NotificationsTable
                    notifications={notificationsMemoized}
                    itemData={itemData}
                    cancelNotification={(id) => cancel(id, handoverItemType)}
                  />
                )}
              </div>
            </Paper>
          </>
        )}{' '}
      </Grid>
    </>
  );
}

export default MainSendOutView;
